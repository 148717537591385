import { db } from 'lib/api/connection'
import { collection, doc, getDocs, writeBatch } from 'firebase/firestore'

/**
 * Duplicate all documents from the source collection to the target collection.
 * The `id` field in any source document (if present) will not be duplicated.
 *
 * @param sourceCollectionPath
 * @param targetCollectionPath
 */
export const duplicateCollection = async (
  sourceCollectionPath: string,
  targetCollectionPath: string,
) => {
  // create new write batch
  const batch = writeBatch(db)

  // get collection references
  const sourceCollectionRef = collection(db, sourceCollectionPath)
  const targetCollectionRef = collection(db, targetCollectionPath)

  // iterate over source collection documents; add a copy of each to the batch
  const querySnapshot = await getDocs(sourceCollectionRef)
  querySnapshot.forEach(sourceDoc => {
    if (!!sourceDoc) {
      const { id, ...sourceDocDataNoId } = sourceDoc.data() || {} // remove id field if present
      const newDocRef = doc(targetCollectionRef)
      batch.set(newDocRef, sourceDocDataNoId)
    }
  })

  // commit write batch
  await batch.commit()
  console.log(
    `duplicated ${querySnapshot.size} documents from` +
      ` ${sourceCollectionPath} to ${targetCollectionPath}`,
  )
}
