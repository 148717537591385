import React from 'react'
import clsx from 'clsx'
import { Spinner, Tooltip } from '@chakra-ui/react'

type Props = {
  onClick: any
  icon: React.FC<any>
  disabled?: boolean
  color?: string
  pending: boolean
  tip?: string
}

export default function IconButton(props: Props) {
  const color = props.color || 'gray.500'

  const icon = (
    <div
      className={clsx('p-0.5', {
        'cursor-pointer': !props.disabled,
        'cursor-not-allowed': props.disabled,
      })}
    >
      {props.pending && <Spinner size="xs" />}
      {!props.pending && (
        <props.icon
          fontSize="20px"
          name={props.icon as any}
          color={!props.disabled ? color : 'gray.300'}
          onClick={props.onClick}
        />
      )}
    </div>
  )

  if (props.tip)
    return (
      <Tooltip label={props.tip} aria-label={props.tip} placement="top">
        {icon}
      </Tooltip>
    )

  return icon
}
