import React from 'react'
import { QueryConstraint } from 'firebase/firestore'

import { getCollection, listenToCollection } from 'lib/api/connection'

export default function useCollection<T>(
  path: string,
  queries?: QueryConstraint[],
  listen?: boolean,
): [any[], boolean, () => Promise<any>] {
  const [pending, setPending] = React.useState(true)
  const [collection, setCollection] = React.useState<T[]>([])

  const onCollectionReceived = React.useCallback(items => {
    setCollection(items)
    setPending(false)
  }, [])

  const fetch = React.useCallback(async () => {
    setPending(true)
    const items = await getCollection<T>(path, queries)
    setCollection(items)
    setPending(false)
  }, [path]) // eslint-disable-line

  React.useEffect(() => {
    if (listen) listenToCollection<T>(path, onCollectionReceived, queries)
    else fetch()
  }, []) // eslint-disable-line

  return [collection, pending, fetch]
}
