import React, { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { Flex, Box, Text, Tooltip } from '@chakra-ui/react'
import { DeleteIcon, CopyIcon } from '@chakra-ui/icons'
import IPause from 'domains/pauses/models/IPause'

interface IProps extends IPause {
  onDelete: (pause: IPause) => any
  onDuplicate: (pause: IPause) => any
}

export default function V2PauseRow(props: IProps) {
  const onDelete = async (e: MouseEvent) => {
    e.preventDefault()
    await props.onDelete(props)
  }
  const onDuplicate = async (e: MouseEvent) => {
    e.preventDefault()
    await props.onDuplicate(props)
  }

  return (
    <Link to={`/v2pauses/${props.id}`}>
      <Flex
        p={3}
        bg="white"
        direction="row"
        justify="space-between"
        align="center"
        cursor="pointer"
      >
        <Box>
          <Text fontSize="lg">{props.title}</Text>
          <Flex direction="row" align="center">
            <Text>{props.published ? 'Published' : 'Unpublished'}</Text>
          </Flex>
        </Box>

        <Flex direction="row" align="center">
          <Box onClick={onDuplicate} cursor="pointer" mr={2}>
            <Tooltip label="Duplicate" aria-label="Duplicate">
              <CopyIcon />
            </Tooltip>
          </Box>
          <Box onClick={onDelete} cursor="pointer" mr={2}>
            <Tooltip label="Delete" aria-label="Delete">
              <DeleteIcon />
            </Tooltip>
          </Box>
        </Flex>
      </Flex>
    </Link>
  )
}
