import { useEffect, useState } from 'react'

import { getDoc, updateDoc } from 'lib/api/connection'

export default function useDoc<T>(
  path: string,
): [T | null, boolean, (d: any) => Promise<T>] {
  const [pending, setPending] = useState(true)

  const [data, internalSetData] = useState<T | null>(null)
  useEffect(() => {
    const fetch = async () => {
      const doc = await getDoc<T>(path)
      setPending(false)
      internalSetData(doc)
    }
    fetch()
  }, [path])

  const setData = async (d: any) => {
    const updated = await updateDoc<T>(path, d)
    internalSetData(updated)
    return updated
  }

  return [data, pending, setData]
}
