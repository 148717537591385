import React, { useState } from 'react'
import { Box, Flex, Spinner, Text, Icon } from '@chakra-ui/react'

import {
  ISegment,
  isBreathSegment,
  isTextSegment,
} from 'domains/segments/models/ISegment'
import useDoc from 'lib/api/hooks/useDoc'
import IPause, { getPausePath } from 'domains/pauses/models/IPause'
import SegmentPreview from 'domains/pauses/components/SegmentPreview'
import Dialog from 'lib/components/Dialog'
import SegmentEditorModal from 'domains/pauses/components/SegmentEditorModal'
import { dotJoinItems } from 'lib/utils/strings'
import { milliSecondsToFriendlyTime } from 'lib/utils/time'
import { deleteDoc } from 'lib/api/connection'

interface IProps extends ISegment {
  pause: IPause
}

export default function SegmentEditorRow(props: IProps) {
  const segmentPath = `${getPausePath(props.pause.id)}/segments/${props.id}`
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)

  const [data] = useDoc<ISegment | null>(segmentPath)

  if (data === null) {
    return (
      <Flex
        direction="row"
        justify="space-between"
        align="center"
        p={3}
        bg="white"
      >
        <Spinner size="sm" />
      </Flex>
    )
  }

  const segment = data as ISegment

  const onDelete = () => setDeleteOpen(true)
  const onCloseDelete = () => setDeleteOpen(false)
  const onDeleteConfirmed = async () => {
    await deleteDoc(segmentPath)
  }

  const onEdit = () => setEditOpen(true)
  const onCloseEdit = () => setEditOpen(false)

  return (
    <Flex direction="row" align="center" p={3} bg="white">
      <Flex direction="column" justify="space-between" width={300} mr={3}>
        <SegmentPreview segment={segment} />
      </Flex>

      <Box>
        <Text mr={3} mb={2}>
          {dotJoinItems([
            `Start: ${milliSecondsToFriendlyTime(segment.start)}`,
            `End: ${milliSecondsToFriendlyTime(segment.end)}`,
            `Branch: ${segment.branch || 0}`,
          ])}
        </Text>
        {isBreathSegment(segment.type) && (
          <Text mr={3} mb={2}>
            Breathe Message: {segment.breathMessage}
          </Text>
        )}
        {isTextSegment(segment.type) && (
          <Text mr={3} mb={2}>
            Caption: {segment.asset}
          </Text>
        )}
      </Box>

      <Flex flex={1} />
      <Flex direction="row" align="center">
        <Box onClick={onEdit} cursor="pointer" ml={2}>
          <Icon name="edit" />
        </Box>
        <Box onClick={onDelete} cursor="pointer" ml={2}>
          <Icon name="delete" />
        </Box>
      </Flex>

      {editOpen && (
        <SegmentEditorModal
          isOpen={editOpen}
          pause={props.pause}
          segment={segment}
          close={onCloseEdit}
        />
      )}
      {deleteOpen && (
        <Dialog
          isOpen={deleteOpen}
          message="Are you sure you want to delete this segment?"
          onConfirm={onDeleteConfirmed}
          onDeny={onCloseDelete}
          icon="delete"
        />
      )}
    </Flex>
  )
}
