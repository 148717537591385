import path from 'path-browserify'

export const ONE_GB_SIZE = 1024 * 1024 * 1024

export const isFileLargerThanOneGB = (fileSize: number) =>
  fileSize >= ONE_GB_SIZE

export const ZIP_EXTENSION = '.zip'
export const PNG_EXTENSION = '.png'
export const JPG_EXTENSION = '.jpg'

export type FileExtensions = '.zip' | '.png' | '.jpg'

export const getFileExtension = (name: string) => path.extname(name)

export const hasFileExtension = (name: string, ext: FileExtensions) =>
  getFileExtension(name) === ext

export const readFileText = (file: File) =>
  new Promise(resolve => {
    var reader = new FileReader()
    reader.onload = function(event) {
      resolve(event.target?.result)
    }
    reader.readAsText(file)
  })
