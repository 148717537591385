import React, { useRef } from 'react'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import { Flex, Icon, Text } from '@chakra-ui/react'

const DRAG_TYPE = 'DRAGGABLE'

interface IItemIndex {
  id: string
  title: string
}

export interface IProps {
  item: IItemIndex
  index: number
  move: (dragIndex: number, hoverIndex: number) => void
}

interface IDragItem {
  id: string
  index: number
}
const DraggableRow: React.FC<IProps> = ({ item, index, move }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [, drop] = useDrop({
    accept: DRAG_TYPE,
    hover(i: IDragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = i.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current!.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      move(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      i.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DRAG_TYPE,
    item: { id: item.id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  drag(drop(ref))

  return (
    <Flex
      ref={ref}
      direction="row"
      align="center"
      cursor="move"
      p={3}
      my={1}
      border="1px dashed #343434"
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <Icon name="drag-handle" mr={3} />
      <Text fontSize="lg" mr={2}>
        {item.title}
      </Text>
    </Flex>
  )
}

export default DraggableRow
