import { db } from 'lib/api/connection'
import { writeBatch, collection, getDocs } from 'firebase/firestore'

/**
 * Delete all documents in a collection.
 * Sub collections of documents are not deleted.
 *
 * @param collectionPath The path of the collection to delete.
 */
export const deleteCollection = async (
  collectionPath: string,
): Promise<void> => {
  // create new write batch
  const batch = writeBatch(db)

  // get collection reference
  const collectionRef = collection(db, collectionPath)

  // iterate over collection documents; delete each one to the batch
  const querySnapshot = await getDocs(collectionRef)
  querySnapshot.forEach(queryDoc => {
    if (!!queryDoc) {
      batch.delete(queryDoc.ref)
    }
  })

  // commit write batch
  await batch.commit()
  console.log(`deleted ${querySnapshot.size} documents from ${collectionPath}`)
}
