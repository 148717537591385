import React from 'react'
import { orderBy } from 'firebase/firestore'

import { List } from 'lib/components'
import PauseRow from 'domains/pauses/components/PauseRow'
import { DEFAULT_ORG_PAUSES_PATH } from 'lib/api/apiConstants'
import CreatePauseModal from 'domains/pauses/components/CreatePauseModal'
import { duplicatePause } from 'domains/pauses/workflows/duplicatePause'
import { RouteComponentProps } from 'react-router'
import Dialog from 'lib/components/Dialog'
import { hasValue } from 'lib/utils'
import IPause from 'domains/pauses/models/IPause'
import { deletePause } from 'domains/pauses/workflows/deletePause'
import PauseOrderModal from 'domains/pauses/components/PauseOrderModal'
import { Box, Flex, Button } from '@chakra-ui/react'
import Paper from 'lib/components/Paper'

const order = orderBy('orderBy', 'asc')

export default function PausesScreen(props: RouteComponentProps) {
  const [createOpen, setCreateOpen] = React.useState(false)
  const onCreateNewPause = () => setCreateOpen(true)
  const onCloseCreate = () => setCreateOpen(false)

  const [duplicate, setDuplicate] = React.useState<IPause | null>(null)
  const onDuplicateRequested = (pause: IPause) => setDuplicate(pause)
  const onDuplicateDenied = () => setDuplicate(null)
  const onDuplicateConfirmed = async () => {
    const newPause = await duplicatePause((duplicate as IPause).id)
    props.history.push(`/pauses/${newPause.id}`)
  }

  const [deleteThisPause, setDeletePause] = React.useState<IPause | null>(null)
  const onDeleteRequested = (pause: IPause) => setDeletePause(pause)
  const onDeleteDenied = () => setDeletePause(null)
  const onDeleteConfirmed = async () => {
    await deletePause((deleteThisPause as IPause).id)
    setDeletePause(null)
  }

  const [orderPausesOpen, setOrderPausesOpen] = React.useState(false)
  const onOrderPausesOpen = () => setOrderPausesOpen(true)
  const onOrderPausesClose = () => setOrderPausesOpen(false)

  return (
    <Box p={3}>
      <Paper
        title="All Pauses"
        actions={
          <Flex direction="row" align="center">
            <Button onClick={onOrderPausesOpen} colorScheme="blue" mr={2}>
              Re-order Pauses
            </Button>
            <Button onClick={onCreateNewPause} colorScheme="green">
              Create New
            </Button>
          </Flex>
        }
      >
        <List
          collection={DEFAULT_ORG_PAUSES_PATH}
          RowComponent={PauseRow}
          queries={[order]}
          rowProps={{
            onDuplicate: onDuplicateRequested,
            onDelete: onDeleteRequested,
          }}
        />
      </Paper>

      <Dialog
        isOpen={hasValue(duplicate)}
        title={`Duplicate '${
          hasValue(duplicate) ? (duplicate as IPause).title : ''
        }'?`}
        icon="copy"
        onConfirm={onDuplicateConfirmed}
        onDeny={onDuplicateDenied}
      />

      <Dialog
        isOpen={hasValue(deleteThisPause)}
        title={`Delete '${
          hasValue(deleteThisPause) ? (deleteThisPause as IPause).title : ''
        }'?`}
        icon="delete"
        confirmVariant="red"
        onConfirm={onDeleteConfirmed}
        onDeny={onDeleteDenied}
        doubleConfirm={true}
      />

      <CreatePauseModal isOpen={createOpen} close={onCloseCreate} version={1} />
      <PauseOrderModal isOpen={orderPausesOpen} close={onOrderPausesClose} />
    </Box>
  )
}
