import * as R from 'ramda'
import moment, { Moment } from 'moment'

import { ITimeStamp } from 'lib/api/connection'

export const getMoment = (
  date: Moment | number | string | ITimeStamp | undefined = undefined,
) => {
  if (!date) {
    return moment()
  }
  if (moment.isMoment(date)) {
    return moment(date)
  }

  if (R.is(Number, date) || R.has('seconds', date)) {
    const seconds = R.has('seconds', date) ? (date as ITimeStamp).seconds : date
    const unix = moment.unix(seconds as number)
    if (unix.year() > 3000) {
      return moment(date)
    }
    return unix
  }

  return moment(date)
}

export const formatDate = (date, format = 'MMMM D, YYYY') =>
  date ? getMoment(date).format(format) : ''

export const formatForDisplay = date => formatDate(date, 'MM/D/YYYY hh:mm A')

export const formatMSToMinsSecsMs = (ms: any) => {
  if (`${ms}`.length < 1) return '00:00.000'
  return new Date(ms).toISOString().slice(14, -1)
}
