import React from 'react'
import * as R from 'ramda'
import { Flex, Text } from '@chakra-ui/react'
import {
  CopyIcon,
  DeleteIcon,
  LockIcon,
  SpinnerIcon,
  ArrowUpIcon,
} from '@chakra-ui/icons'

import ModalWrapper from 'lib/components/ModalWrapper'

type DialogIcon = 'delete' | 'lock' | 'copy' | 'spinner' | 'arrowUp'
const ICON_NAME_MAP: Record<DialogIcon, any> = {
  delete: DeleteIcon,
  lock: LockIcon,
  copy: CopyIcon,
  spinner: SpinnerIcon,
  arrowUp: ArrowUpIcon,
}

type Props = {
  isOpen: boolean
  title?: string
  icon?: DialogIcon
  message?: any
  confirmText?: string
  confirmVariant?: string
  denyText?: string
  onConfirm: (args?: object) => void
  onDeny?: () => void
  args?: object
  doubleConfirm?: boolean
}

export default function Dialog(props: Props) {
  const [pending, setPending] = React.useState(false)
  const [confirmedOnce, setConfirmedOnce] = React.useState(false)

  React.useEffect(() => {
    if (!props.isOpen && pending) setPending(false)
  }, [props.isOpen, pending])

  const onConfirm = async () => {
    if (props.doubleConfirm && !confirmedOnce) {
      setConfirmedOnce(true)
    } else {
      setPending(true)
      await props.onConfirm(props.args)
      setConfirmedOnce(false)
      setPending(false)
    }
  }

  const onDeny = async () => {
    if (props.onDeny) {
      setPending(true)
      await props.onDeny()
      setPending(false)
    }
  }

  const actions = [
    {
      text: props.confirmText || 'Yes',
      colorScheme: props.confirmVariant || 'green',
      onClick: onConfirm,
    },
  ]
  if (props.onDeny) {
    actions.push({
      text: props.denyText || 'Cancel',
      colorScheme: 'gray',
      onClick: onDeny,
    })
  } else {
    actions.push({
      text: props.denyText || 'Cancel',
      colorScheme: 'gray',
      onClick: onDeny,
    })
  }

  const renderMessage = () => {
    const { message } = props
    if (!message) {
      return null
    }
    if (R.is(String, message)) {
      return <Text textAlign="center">{message}</Text>
    }
    if (R.is(Function, message)) {
      return message()
    }

    return message
  }

  const { icon, message, title, isOpen } = props
  const Icon = ICON_NAME_MAP[`${icon}`]

  const titlePrefix = confirmedOnce ? 'NO SERIOUSLY, ' : ''
  return (
    <ModalWrapper
      isOpen={isOpen}
      title={`${titlePrefix}${title || 'Are you sure?'}`}
      actions={actions}
      pending={pending}
    >
      {(message || icon) && (
        <Flex direction="column" align="center">
          <Icon fontSize="86px" pb={4} data-test="modal-icon" name={icon} />
          {renderMessage()}
        </Flex>
      )}
    </ModalWrapper>
  )
}
