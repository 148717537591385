import * as R from 'ramda'

import { getPauseSegmentsPath } from 'domains/pauses/models/IPause'
import { hasValue } from 'lib/utils'
import { ISegment, getSegmentPath } from 'domains/segments/models/ISegment'
import { getCollection, updateDoc } from 'lib/api/connection'
import { orderBy } from 'firebase/firestore'

const order = orderBy('start', 'asc')

export const cascadeSegmentTimes = async (
  pauseId: string,
  segmentId: string,
  newEnd: number,
  branch?: number,
) => {
  const data = await getCollection(getPauseSegmentsPath(pauseId), [order])

  if (!hasValue(data)) {
    return
  }

  const pauseSegments = data as ISegment[]
  const previousSegment = R.find(R.propEq('id', segmentId), pauseSegments)
  if (!previousSegment) {
    return
  }
  const segmentsAfter = pauseSegments.filter(s => {
    const isAfter = s.start >= previousSegment.start
    if (branch !== undefined) {
      return isAfter && s.branch === (branch === 0 ? undefined : branch)
    }
    return isAfter
  })
  const delta = newEnd - previousSegment.end

  for (const segment of segmentsAfter) {
    await updateDoc(getSegmentPath(pauseId, segment.id), {
      ...segment,
      start: segment.start + delta,
      end: segment.end + delta,
    })
  }
}
