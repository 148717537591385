import React from 'react'
import { Flex, Button, Spinner, Box, Text } from '@chakra-ui/react'

import Paper from 'lib/components/Paper'
import { IOrgAnnouncement } from 'domains/organization/models/IOrganization'
import useModalState from 'lib/api/hooks/useModalState'
import useAnnouncement from 'domains/organization/hooks/useAnnouncement'
import AnnouncementEditorModal from 'domains/organization/components/AnnouncementEditorModal'

export default function OrgAnnouncement() {
  const [announcementData, pending] = useAnnouncement()
  const [editOpen, openEditModal, closeEditModal] = useModalState()

  if (pending) {
    return (
      <Paper title="Announcement">
        <Spinner />
      </Paper>
    )
  }

  const announcement = announcementData as IOrgAnnouncement
  return (
    <Paper
      title="Announcement"
      actions={
        <Flex direction="row" align="center">
          <Button onClick={openEditModal} colorScheme="green" mr={2}>
            Edit
          </Button>
        </Flex>
      }
    >
      <Box p={3}>
        <Text>
          Visible for all users:{' '}
          <span style={{ fontWeight: 700 }}>
            {announcement.visible ? 'TRUE' : 'FALSE'}
          </span>
        </Text>
        {announcement.visible && (
          <Box pt={3}>
            <Text>{announcement.bannerTitle || ''}</Text>
          </Box>
        )}
      </Box>

      {editOpen && <AnnouncementEditorModal close={closeEditModal} />}
    </Paper>
  )
}
