import React, { useCallback, useState, useEffect } from 'react'
import * as R from 'ramda'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { orderBy } from 'firebase/firestore'
import { DndProvider } from 'react-dnd'

import ModalWrapper from 'lib/components/ModalWrapper'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'
import { hasValue } from 'lib/utils'
import useCollection from 'lib/api/hooks/useCollection'
import IBookPractice, {
  getBookPracticePath,
} from 'domains/organization/models/IBookPractice'
import DraggableRow from 'domains/pauses/components/DraggableRow'
import { updateDoc } from 'lib/api/connection'

const order = [orderBy('orderBy', 'asc')]

interface IProps {
  isOpen: boolean
  close: () => any
}

export default function BookPracticesOrderModal(props: IProps) {
  const [collection] = useCollection(`${DEFAULT_ORG_PATH}/bookPractices`, order)

  const [list, setList] = useState(collection as IBookPractice[])
  const [pending, setPending] = useState(false)

  useEffect(() => {
    setList(collection)
  }, [collection])

  const move = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const item = list[dragIndex]
      const newList = R.reject(R.propEq('id', item.id), list)
      newList.splice(hoverIndex, 0, item)
      setList(newList)
    },
    [list],
  )

  const onSave = async () => {
    setPending(true)
    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      await updateDoc(getBookPracticePath(item.id), { ...item, orderBy: i })
    }
    setPending(false)
    props.close()
  }

  if (!hasValue(collection)) {
    return null
  }

  return (
    <ModalWrapper
      isOpen={props.isOpen}
      title="Book Excerpts Order"
      pending={pending}
      actions={[
        { text: 'Save', onClick: onSave },
        { text: 'Cancel', colorScheme: 'gray', onClick: props.close },
      ]}
    >
      <DndProvider backend={HTML5Backend}>
        {list.map((item: IBookPractice, index: number) => (
          <DraggableRow
            key={item.id}
            item={{ ...item, title: item.name }}
            index={index}
            move={move}
          />
        ))}
      </DndProvider>
    </ModalWrapper>
  )
}
