import React from 'react'
import { Flex, Box, Text, Icon } from '@chakra-ui/react'

import IBookPractice from 'domains/organization/models/IBookPractice'

interface IProps extends IBookPractice {
  onEdit: (bookPractice: IBookPractice) => any
  onDelete: (bookPractice: IBookPractice) => any
}

export default function BookPracticeRow(props: IProps) {
  const onEdit = () => props.onEdit(props)
  const onDelete = e => {
    e.stopPropagation()
    props.onDelete(props)
  }

  return (
    <Flex
      p={3}
      bg="white"
      direction="row"
      justify="space-between"
      align="center"
      cursor="pointer"
      onClick={onEdit}
    >
      <Box>
        <Text fontSize="lg">{props.name}</Text>
      </Box>

      <Flex direction="row" align="center">
        <Box onClick={onDelete} cursor="pointer" mr={2}>
          <Icon name="delete" />
        </Box>
      </Flex>
    </Flex>
  )
}
