import React, { ComponentType, PureComponent } from 'react'
import * as R from 'ramda'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { getDisplayName } from 'lib/utils/hoc'

export interface IWithNavigationProps extends RouteComponentProps {} // tslint:disable-line

const withNavigationProps = (WrappedComponent: ComponentType<any>) => {
  class MapNavigationParams extends PureComponent<any> {
    public static displayName = getDisplayName(
      'WithNavigationProps',
      WrappedComponent,
    )

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...R.pathOr({}, ['match', 'params'], this.props)}
          {...queryString.parse(
            R.pathOr('', ['location', 'search'], this.props),
          )}
        />
      )
    }
  }
  MapNavigationParams.displayName = getDisplayName(
    'MapNavigationParams',
    WrappedComponent,
  )
  return withRouter(MapNavigationParams)
}

export default withNavigationProps

export const getNavigationProps = (props: any) => ({
  ...R.pathOr({}, ['match', 'params'], props),
  ...queryString.parse(R.pathOr('', ['location', 'search'], props)),
})

export const getNavigationProp = (
  props: any,
  prop: string,
  fallback: any = false,
) => R.propOr(fallback, prop, getNavigationProps(props))
