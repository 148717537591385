import { duplicateDocument } from './duplicateDocument'
import { DEFAULT_ORG_V2_PAUSES_PATH } from 'lib/api/apiConstants'
import { getDoc } from 'lib/api/connection'
import IPause from 'domains/pauses/models/IPause'

/**
 * Duplicate a Pause document and all its sub collections.
 *
 * @param pauseId The ID of the Pause document to duplicate.
 * @return The ID of the newly created Pause document.
 */
export const duplicatePause = async (pauseId: string): Promise<string> => {
  // build field overwrites object
  const docData = await getDoc<IPause>(
    `${DEFAULT_ORG_V2_PAUSES_PATH}/${pauseId}`,
  )
  const fieldOverwrites = {
    published: false,
    ...(!!docData.title && {
      title: docData.title + ' (Copy)',
    }),
  }

  // duplicate Pause document
  return await duplicateDocument(DEFAULT_ORG_V2_PAUSES_PATH, pauseId, {
    fieldOverwrites,
    collectionsToDuplicate: ['transcripts'],
  })
}
