import React from 'react'
import { Box } from '@chakra-ui/react'
import { RouteComponentProps } from 'react-router'

import UpdateOrgUnlockingRequirements from 'domains/organization/components/UpdateOrgUnlockingRequirements'
import UpdatePauseIntros from 'domains/organization/components/UpdatePauseIntros'
import UpdateBookExcerpts from 'domains/organization/components/UpdateBookExcerpts'
import OrgAnnouncement from 'domains/organization/components/OrgAnnouncement'
import OrgForceUpgrade from '../components/OrgForceUpgrade'
import Onboarding from '../components/Onboarding'
import HeartCheck from '../components/HeartCheck'

export default function OrganizationDetailsScreen(props: RouteComponentProps) {
  return (
    <Box p={3}>
      <OrgAnnouncement />

      <Box mt={3} />
      <Onboarding />

      <Box mt={3} />
      <HeartCheck />

      <Box mt={3} />
      <UpdateBookExcerpts />

      <Box mt={3} />
      <OrgForceUpgrade />

      <Box mt={3} />
      <UpdateOrgUnlockingRequirements />

      <Box mt={3} />
      <UpdatePauseIntros />
    </Box>
  )
}
