const complete = '#448eca'
const primary = '#1d4a5d'
const secondary = '#3b8f71'
const tertiary = '#f2e9d4'
const background = '#fff'

const text = '#444444'
const textLight = '#6c757d'
const neutral = '#c2c2c2'
const textHighlight = tertiary

export interface IColors {
  primary: string
  secondary: string
  tertiary: string
  background: string
  complete: string
  error: string
  errorLight: string
  start: string
  paused: string
  success: string
  warning: string
  warningAlt: string
  info: string
  infoLight: string
  infoAlert: string
  focusShadow: string
  white: string
  neutral: string
  text: string
  textLight: string
  textHighlight: string
  placeholder: string
  builtInMetaDataBG: string
  customMetaDataBG: string
  comparisonReportGreenBG: string
  comparisonReportAmberBG: string
  comparisonReportRedBG: string
  reportTableBorder: string
  chartColors: string[]
}

const colors: IColors = {
  primary,
  secondary,
  tertiary,
  background,
  complete,
  error: '#ED1C24',
  errorLight: '#fed7d9',
  start: '#3CB878',
  paused: secondary,
  success: '#00a651',
  warning: '#FFCA29',
  warningAlt: '#E9821E',
  info: tertiary,
  infoLight: '#6DCFF6',
  infoAlert: '#fffcda',
  focusShadow: 'rgba(0,123,255,.25)',
  white: '#fff',
  neutral,
  builtInMetaDataBG: '#dde7f5',
  customMetaDataBG: '#fffcda',

  comparisonReportGreenBG: '#daf2e4',
  comparisonReportAmberBG: '#fbe6d3',
  comparisonReportRedBG: '#fdcbcd',
  reportTableBorder: '#ebebec',

  // Font colors
  text,
  textLight,
  textHighlight,
  placeholder: '#a3a3a3',

  chartColors: [
    primary,
    secondary,
    '#F564A9',
    '#242038',
    complete,
    '#5CC8FF',
    '#FAA4BD',
    '#FAE3C6',
    '#725AC1',
    '#8D86C9',
    '#EE9480',
    '#7D70BA',
  ],
}

export default colors
