import React, { PureComponent } from 'react'
import { connect, FormikContextType } from 'formik'
import * as R from 'ramda'
import { Text } from '@chakra-ui/react'
import colors from 'lib/styles/colors'
import { hasValue, toPath } from 'lib/utils'

interface IValues {
  [s: string]: any
}
interface IFormikProps {
  formik: FormikContextType<IValues>
}
interface IOuterProps {
  name: string
  showImmediately?: boolean
  showRequiredOnlyOnSumbit?: boolean
}
class FormErrorMessage extends PureComponent<IFormikProps & IOuterProps> {
  render() {
    const {
      formik: { submitCount, touched, errors },
      name,
      showImmediately = false,
      showRequiredOnlyOnSumbit = false,
    } = this.props

    const hasSubmitted = submitCount > 0
    const error = R.pathOr<string>('', toPath(name), errors)
    const shouldShow = showImmediately
      ? true
      : showRequiredOnlyOnSumbit && (error !== 'Required' || hasSubmitted)
      ? true
      : R.prop(name, touched)

    if (hasValue(error) && (shouldShow || hasSubmitted)) {
      return (
        <Text color={colors.error} mt={2} data-test={`form-error-${name}`}>
          {error}
        </Text>
      )
    }
    return null
  }
}

export default connect<IOuterProps>(FormErrorMessage)
