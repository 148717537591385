import React from 'react'
import { Flex, Box, Text, Image } from '@chakra-ui/react'

import { ISegment } from 'domains/segments/models/ISegment'
import SegmentTypeIcon from 'domains/segments/components/SegmentTypeIcon'

interface IProps {
  segment: ISegment
}

const getContent = (segment: ISegment) => {
  switch (segment.type) {
    case 'BREATHE_START':
    case 'BREATHE_IN':
    case 'BREATHE_OUT':
    case 'CAPTION':
      return <SegmentTypeIcon segment={segment} fontSize={54} />
    case 'MUSIC':
    case 'VOICE':
      return (
        <audio controls={true}>
          <source src={segment.asset} />
        </audio>
      )
    case 'VIDEO':
      return (
        <video width={200} controls={true}>
          <source src={segment.asset} />
        </video>
      )
    case 'IMAGE':
      return (
        <Image src={segment.asset} style={{ maxWidth: 200, maxHeight: 200 }} />
      )
    default:
      return null
  }
}

export default function SegmentPreview(props: IProps) {
  return (
    <Flex direction="column" justify="center" align="center">
      {getContent(props.segment)}

      <Box my={2}>
        <Text>TYPE: {props.segment.type}</Text>
        <Text fontSize="italic">{props.segment.name || ''}</Text>
      </Box>
    </Flex>
  )
}
