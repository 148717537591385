import React, { useState, useEffect, createContext } from 'react'
import { onAuthStateChanged } from 'firebase/auth'

import AppLoadingScreen from 'domains/application/screens/AppLoadingScreen'
import AppScreen from 'domains/application/screens/AppScreen'
import IUser from 'domains/authentication/models/IUser'
import { auth, getDoc } from 'lib/api/connection'

export const UserContext = createContext<IUser | boolean>(false)

export default function StartupScreen() {
  const [user, setUser] = useState<IUser | null | boolean>(null)

  useEffect(() => {
    onAuthStateChanged(auth, async (authUser: any) => {
      if (authUser) {
        const checkUser = await getDoc<IUser>(`users/${authUser.uid}`)
        if (checkUser.admin) {
          setUser(checkUser)
        } else {
          setUser(false)
        }
      } else {
        setUser(false)
      }
    })
  }, [])

  if (user === null) {
    return <AppLoadingScreen />
  }

  return (
    <UserContext.Provider value={user}>
      <AppScreen />
    </UserContext.Provider>
  )
}
