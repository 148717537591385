import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Header from 'domains/application/components/Header'
import PauseDetailsScreen from 'domains/pauses/screens/PauseDetailsScreen'
import OrganizationDetailsScreen from 'domains/organization/screens/OrganizationDetailsScreen'
import PausesScreen from 'domains/application/screens/PausesScreen'
import V2PauseDetailsScreen from '@/pauses/screens/V2PauseDetailsScreen'
import V2PausesScreen from '@/pauses/screens/V2PausesScreen'
import ProgramsScreen from '@/pauses/screens/ProgramsScreen'
import ProgramDetailsScreen from '@/pauses/screens/ProgramDetailsScreen'
import PauseTranscriptsEditorScreen from '@/pauses/screens/PauseTranscriptsEditorScreen'
import UsersScreen from 'domains/users/screens/UsersScreen'
import { UserDetailsScreen } from 'domains/users/screens/UserDetailsScreen'

export default function MainRouter() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <Header />
      <div className="container mx-auto py-6">
        <Switch>
          <Route exact={true} path="/v2pauses" component={V2PausesScreen} />
          <Route
            exact={true}
            path="/v2pauses/:id"
            component={V2PauseDetailsScreen}
          />
          <Route
            exact={true}
            path="/v2pauses/:id/transcripts"
            component={PauseTranscriptsEditorScreen}
          />
          <Route exact={true} path="/programs" component={ProgramsScreen} />
          <Route
            exact={true}
            path="/programs/:id"
            component={ProgramDetailsScreen}
          />
          <Route exact={true} path="/pauses" component={PausesScreen} />
          <Route
            exact={true}
            path="/pauses/:id"
            component={PauseDetailsScreen}
          />
          <Route
            exact={true}
            path="/organization"
            component={OrganizationDetailsScreen}
          />
          <Route exact={true} path="/users" component={UsersScreen} />
          <Route exact={true} path="/users/:id" component={UserDetailsScreen} />
          <Redirect key="redirect" to="/v2pauses" />
        </Switch>
      </div>
    </div>
  )
}
