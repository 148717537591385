import React from 'react'
import srtParser2 from 'srt-parser-2'

import useModalState from 'lib/api/hooks/useModalState'
import FileInput from 'lib/components/FileInput'
import ModalWrapper from 'lib/components/ModalWrapper'
import { readFileText } from 'lib/utils/files'
import { formatMSToMinsSecsMs } from 'lib/utils/date'
import {
  getPauseTranscriptPath,
  getPauseTranscriptsPath,
  IPauseTranscript,
} from '@/pauses/models/IPause'
import { addDoc, deleteDoc } from 'lib/api/connection'
import { parseSRTTimeToMS } from 'lib/utils/time'

type Props = {
  pauseId: string
  name: string
  currentTranscripts: IPauseTranscript[]
  onUpdated: () => any
}

export default function CaptionFileInput(props: Props) {
  const ref = React.useRef<HTMLInputElement>(null)
  const [transcripts, setTranscripts] = React.useState<IPauseTranscript[]>([])
  const [pending, setPending] = React.useState(false)
  const [isModalOpen, openModal, closeModal] = useModalState()

  const onCancel = () => {
    setTranscripts([])
    closeModal()
    if (ref.current) ref.current.value = ''
  }

  const onFilePicked = async (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const srt = await readFileText(e.currentTarget.files[0])
      var parser = new srtParser2()
      var result = parser.fromSrt(`${srt}`)
      setTranscripts(
        result.map(
          r =>
            ({
              transcript: r.text,
              start: parseSRTTimeToMS(r.startTime),
              end: parseSRTTimeToMS(r.endTime),
            } as IPauseTranscript),
        ),
      )
      openModal()
    }
  }

  const onSave = async () => {
    setPending(true)
    if (ref.current) ref.current.value = ''
    const deletePromises = transcripts.map(t =>
      deleteDoc(getPauseTranscriptPath(props.pauseId, t.id)),
    )
    await Promise.all(deletePromises)

    const createPromises = transcripts.map(t =>
      addDoc(getPauseTranscriptsPath(props.pauseId), t),
    )
    await Promise.all(createPromises)

    props.onUpdated()
    setPending(false)
    closeModal()
  }

  return (
    <>
      <FileInput
        ref={ref}
        name={props.name}
        text="Browse"
        onChange={onFilePicked}
        accept=".srt"
      />
      <ModalWrapper
        title="New Transcripts"
        isOpen={isModalOpen}
        actions={[
          {
            text: 'Save Transcripts',
            colorScheme: 'green',
            onClick: onSave,
            extra: { type: 'submit', isLoading: pending },
          },
          {
            text: 'Cancel',
            colorScheme: 'gray',
            onClick: onCancel,
            extra: { type: 'button', isLoading: pending },
          },
        ]}
      >
        <div className="space-y-4">
          <h3 className="text-md text-gray-700 font-bold">
            Found {transcripts.length} transcripts. Are you sure you want to
            overwrite any existing transcripts?
          </h3>

          <div className="divide-y-2 space-y-2">
            {transcripts.map((trx, index) => (
              <div
                key={`${trx.start}-${trx.end}-${index}`}
                className="flex space-x-4 pt-2"
              >
                <div className="min-h-full flex items-center justify-center text-gray-600">
                  <p className="text-xl font-bold">{index + 1}</p>
                </div>
                <div className="text-xs">
                  <p>start: {formatMSToMinsSecsMs(trx.start)}</p>
                  <p>end: {formatMSToMinsSecsMs(trx.end)}</p>
                  <p className="pt-2 text-sm">{trx.transcript}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ModalWrapper>
    </>
  )
}
