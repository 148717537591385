import { IPauseTranscript } from '@/pauses/models/IPause'
import { getSrtTimeFromMillis } from './time'
import srtParser2, { Line } from 'srt-parser-2'

/**
 * Convert Pause transcript data to .srt file format.
 *
 * @param transcripts Pause transcripts.
 * @returns .srt file contents
 */
export const convertTranscriptsToSrtFileFormat = (
  transcripts: IPauseTranscript[],
): string => {
  // create intermediate data from transcripts
  const intermediate: Line[] = transcripts.map((transcript, index) => ({
    id: (index + 1).toString(),
    startTime: getSrtTimeFromMillis(transcript.start),
    endTime: getSrtTimeFromMillis(transcript.end),
    text: transcript.transcript,
  }))

  // use `srtParser2` to create the SRT formatted data
  const parser = new srtParser2()
  return parser.toSrt(intermediate)
}

/**
 * Convert Pause transcript data to .txt file format.
 *
 * @param transcripts Pause transcripts.
 * @returns .txt file contents
 */
export const convertTranscriptsToTxtFileFormat = (
  transcripts: IPauseTranscript[],
): string => {
  const txtData = transcripts.reduce(
    (accumulator, pauseTranscript: IPauseTranscript) =>
      accumulator +
      pauseTranscript.transcript +
      (pauseTranscript.paragraphEnd ? '\n\n' : '\n'),
    '',
  )
  return txtData
}
