import React from 'react'
import { Box, Spinner } from '@chakra-ui/react'
import * as R from 'ramda'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { orderBy } from 'firebase/firestore'

import ModalWrapper from 'lib/components/ModalWrapper'
import IPause, { getPausePath } from 'domains/pauses/models/IPause'
import useCollection from 'lib/api/hooks/useCollection'
import useDoc from 'lib/api/hooks/useDoc'
import { ISegment } from 'domains/segments/models/ISegment'
import { hasValue } from 'lib/utils'
import { getMoment } from 'lib/utils/date'

const order = [orderBy('start', 'asc')]

interface IProps {
  pauseId: string
  isOpen: boolean
  close: () => any
}

export default function GenerateManifestModal(props: IProps) {
  const [pause] = useDoc<IPause | null>(getPausePath(props.pauseId))

  const segmentsPath = `${getPausePath(props.pauseId)}/segments`
  const [segmentsData, pending] = useCollection(segmentsPath, order)
  const segments = segmentsData as ISegment[]
  const fileSegments = segments.filter(
    R.anyPass<any>([
      R.propEq('type', 'IMAGE'),
      R.propEq('type', 'MUSIC'),
      R.propEq('type', 'VOICE'),
    ]),
  )

  React.useEffect(() => {
    const fetch = async () => {
      const zip = new JSZip()
      zip.file('manifest.json', JSON.stringify(fileSegments, null, 2))

      let count = 0
      fileSegments.forEach(segment => {
        JSZipUtils.getBinaryContent(segment.asset, async (err, data) => {
          if (err) {
            throw err
          }
          zip.file(segment.name, data, { binary: true })
          count++
          if (count === fileSegments.length) {
            const zipFile = await zip.generateAsync({ type: 'blob' })
            saveAs(
              zipFile,
              `${pause!.title.replace(' ', '')}_manifest_${getMoment().format(
                'YYYY-MM-DD-HH:mm:ss',
              )}.zip`,
            )
            props.close()
          }
        })
      })
    }

    if (!pending && hasValue(pause) && hasValue(fileSegments)) {
      fetch()
    }
  }, [fileSegments, pending]) // eslint-disable-line

  return (
    <ModalWrapper
      title="Generating Manifest"
      isOpen={props.isOpen}
      actions={[
        {
          text: 'Cancel',
          colorScheme: 'gray',
          onClick: props.close,
          extra: { type: 'button' },
        },
      ]}
    >
      <Box>
        <Spinner />
      </Box>
    </ModalWrapper>
  )
}
