import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Flex, Button, Spinner, Checkbox, Box } from '@chakra-ui/react'
import { Formik, FormikHelpers } from 'formik'
import { orderBy } from 'firebase/firestore'

import Paper from 'lib/components/Paper'
import useDoc from 'lib/api/hooks/useDoc'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'
import IOrganization from 'domains/organization/models/IOrganization'
import IBookPractice from 'domains/organization/models/IBookPractice'
import { List } from 'lib/components'
import useModalState from 'lib/api/hooks/useModalState'
import BookPracticeRow from 'domains/organization/components/BookPracticeRow'
import Dialog from 'lib/components/Dialog'
import { hasValue } from 'lib/utils'
import BookPracticeEditorModal from 'domains/organization/components/BookPracticeEditorModal'
import BookPracticesOrderModal from 'domains/organization/components/BookPracticesOrderModal'
import { deleteDoc } from 'lib/api/connection'

const order = orderBy('orderBy', 'asc')

export default function UpdateBookExcerpts() {
  const [orgData, , updateOrg] = useDoc(DEFAULT_ORG_PATH)
  const [
    toDeleteBookPractice,
    setToDeleteBookPractice,
  ] = useState<null | IBookPractice>(null)

  const [reorderOpen, openReorderModal, closeReorderModal] = useModalState()

  const [createOpen, openCreateModal, closeCreateModal] = useModalState()

  const [deleteOpen, openDeleteModal, closeDeleteModal] = useModalState()

  const onDelete = (bookPractice: IBookPractice) => {
    setToDeleteBookPractice(bookPractice)
    openDeleteModal()
  }

  const onDeleteConfirmed = async () => {
    await deleteDoc(
      `${DEFAULT_ORG_PATH}/bookPractices/${
        (toDeleteBookPractice as IBookPractice).id
      }`,
    )
    closeDeleteModal()
  }

  const [
    toEditBookPractice,
    setToEditBookPractice,
  ] = useState<null | IBookPractice>(null)
  const [editOpen, openEditModal, closeEditModal] = useModalState()
  const onEdit = (bookPractice: IBookPractice) => {
    setToEditBookPractice(bookPractice)
    openEditModal()
  }

  const onSave = async (
    updatedOrg: IOrganization,
    bag: FormikHelpers<IOrganization>,
  ) => {
    await updateOrg({ showBookPractices: updatedOrg.showBookPractices })
    toast.success('Updated')
    bag.setSubmitting(false)
  }

  if (!orgData) {
    return (
      <Paper title="Book Excerpts">
        <Spinner />
      </Paper>
    )
  }

  const org = orgData as IOrganization
  return (
    <Formik initialValues={org} onSubmit={onSave}>
      {bag => {
        return (
          <Paper
            title="Book Excerpts"
            actions={
              <Flex direction="row" align="center">
                <Button
                  onClick={() => bag.handleSubmit()}
                  colorScheme="green"
                  mr={2}
                  isLoading={bag.isSubmitting}
                  loadingText="Saving..."
                >
                  Save
                </Button>
                <Button onClick={openReorderModal} colorScheme="blue" mr={2}>
                  Re-Order Excerpts
                </Button>
                <Button onClick={openCreateModal} colorScheme="green" mr={2}>
                  Create New
                </Button>
              </Flex>
            }
          >
            <Box p={3}>
              <Checkbox
                isChecked={bag.values.showBookPractices}
                onChange={bag.handleChange}
                name="showBookPractices"
              >
                Show In App
              </Checkbox>

              <Box mt={3} />
              <List
                collection={`${DEFAULT_ORG_PATH}/bookPractices`}
                RowComponent={BookPracticeRow}
                queries={[order]}
                rowProps={{
                  onEdit,
                  onDelete,
                }}
              />
            </Box>
            <Dialog
              isOpen={deleteOpen}
              title={`Delete '${
                hasValue(toDeleteBookPractice)
                  ? (toDeleteBookPractice as IBookPractice).name
                  : ''
              }'?`}
              icon="delete"
              confirmVariant="red"
              onConfirm={onDeleteConfirmed}
              onDeny={closeDeleteModal}
              doubleConfirm={true}
            />

            {editOpen && !!toEditBookPractice && (
              <BookPracticeEditorModal
                bookPractice={toEditBookPractice}
                close={closeEditModal}
              />
            )}
            {createOpen && (
              <BookPracticeEditorModal
                isCreate={true}
                close={closeCreateModal}
              />
            )}
            <BookPracticesOrderModal
              isOpen={reorderOpen}
              close={closeReorderModal}
            />
          </Paper>
        )
      }}
    </Formik>
  )
}
