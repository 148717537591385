import { db } from 'lib/api/connection'
import { addDoc, collection, doc, getDoc } from 'firebase/firestore'
import { duplicateCollection } from './duplicateCollection'

/**
 * Duplicate a Firebase document within a collection, including selected
 * sub collections.
 *
 * @param collectionPath The path of the collection in which the document
 *   will be duplicated.
 * @param sourceId The ID of the document to duplicate.
 * @param options Optional parameters, where:
 *    fieldOverwrites: { [key: string]: any }
 *      - An object of field names and values to overwrite in the new document.
 *    collectionsToDuplicate: string[]
 *      - The names of selected sub collections to duplicate.
 * @return The ID of the newly created document.
 */
export const duplicateDocument = async (
  collectionPath: string,
  sourceId: string,
  options?: {
    fieldOverwrites?: { [key: string]: any }
    collectionsToDuplicate?: string[]
  },
): Promise<string> => {
  // start performance timer
  const perfStart = performance.now()

  // get source document
  const sourcePath = `${collectionPath}/${sourceId}`
  const sourceDocRef = await getDoc(doc(db, sourcePath))
  const { id, ...sourceDocDataNoId } = sourceDocRef.data() || {} // remove id field

  // write target document, with source data overlaid by any field overwrites
  const targetData = {
    ...sourceDocDataNoId,
    ...options?.fieldOverwrites,
  }
  const targetDocRef = await addDoc(collection(db, collectionPath), targetData)
  const targetDocId = targetDocRef.id
  const targetPath = `${collectionPath}/${targetDocId}`

  // duplicate specified collections from source document to target document
  // (NOTE: unfortunately listCollections() method is not supported in
  // Firestore web API)
  const subCollections = options?.collectionsToDuplicate || []
  for (const subCollection of subCollections) {
    await duplicateCollection(
      `${sourcePath}/${subCollection}`,
      `${targetPath}/${subCollection}`,
    )
  }

  // end performance timer, log performance info
  const perfDuration = performance.now() - perfStart
  console.log(
    `duplicated document from ${sourcePath} to ${targetPath}` +
      ` with ${subCollections.length} sub-collection(s)` +
      ` in ${perfDuration.toFixed(2)} milliseconds`,
  )

  // return ID of newly created document
  return targetDocId
}
