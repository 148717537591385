import React from 'react'
import Icon, { IIconProps } from 'lib/components/Icon'
import {
  ISegment,
  getIconForSegmentType,
} from 'domains/segments/models/ISegment'

interface IProps extends IIconProps {
  segment: ISegment
}

export default function SegmentTypeIcon(props: IProps) {
  const { segment, ...rest } = props

  return (
    <Icon className="material-icons" {...rest}>
      {getIconForSegmentType(segment.type)}
    </Icon>
  )
}
