import React from 'react'
import { Flex, Button } from '@chakra-ui/react'

import Paper from 'lib/components/Paper'
import useModalState from 'lib/api/hooks/useModalState'
import OrgUnlockingEditorModal from 'domains/organization/components/OrgUnlockingEditorModal'

export default function UpdateOrgUnlockingRequirements() {
  const [isOpen, openModal, closeModal] = useModalState()
  return (
    <Paper
      title="Pause Unlocking Requirements"
      actions={
        <Flex direction="row" align="center">
          <Button onClick={openModal} colorScheme="green" mr={2}>
            Edit
          </Button>
        </Flex>
      }
    >
      <OrgUnlockingEditorModal isOpen={isOpen} close={closeModal} />
    </Paper>
  )
}
