import React from 'react'
import {
  Editable,
  useEditableControls,
  IconButton,
  EditableTextarea,
  Button,
  ButtonGroup,
} from '@chakra-ui/react'
import { CheckIcon, EditIcon, CloseIcon } from '@chakra-ui/icons'

export const ObjectArrayInput = (props: {
  defaultValue: string
  value: { label: string }[]
  onChange: (e: any) => void
}) => {
  return (
    <Editable defaultValue={props.defaultValue}>
      <ObjectArrayInputEditablePreview items={props.value} />
      <EditableTextarea
        value={JSON.stringify(props.value)}
        onChange={props.onChange}
      />
      <ObjectArrayInputEditableControls />
    </Editable>
  )
}

const ObjectArrayInputEditablePreview = (props: {
  items: { label: string }[]
}) => {
  const editableControls = useEditableControls()
  return (
    <>
      {Array.isArray(props.items) && (
        <>
          {props.items.map((item, index) => (
            <Button
              key={index}
              size="md"
              m={1}
              {...editableControls.getEditButtonProps()}
            >
              {item?.label || '???'}
            </Button>
          ))}
        </>
      )}
    </>
  )
}

const ObjectArrayInputEditableControls = () => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls()

  return isEditing ? (
    <ButtonGroup justifyContent="center">
      <IconButton
        aria-label="submit"
        icon={<CheckIcon />}
        {...getSubmitButtonProps()}
      />
      <IconButton
        aria-label="cancel"
        icon={<CloseIcon />}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <IconButton
      m={1}
      aria-label="edit"
      icon={<EditIcon />}
      {...getEditButtonProps()}
    />
  )
}
