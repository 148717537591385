import React, { useCallback, useState, useEffect } from 'react'
import * as R from 'ramda'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { orderBy } from 'firebase/firestore'

import ModalWrapper from 'lib/components/ModalWrapper'
import { DEFAULT_ORG_PAUSES_PATH } from 'lib/api/apiConstants'
import { hasValue } from 'lib/utils'
import useCollection from 'lib/api/hooks/useCollection'
import IPause, { getPausePath } from 'domains/pauses/models/IPause'
import DraggableRow from 'domains/pauses/components/DraggableRow'
import { updateDoc } from 'lib/api/connection'

const order = [orderBy('orderBy', 'asc')]

interface IProps {
  isOpen: boolean
  close: () => any
}

export default function PauseOrderModal(props: IProps) {
  const [collection] = useCollection(DEFAULT_ORG_PAUSES_PATH, order)
  const [list, setList] = useState(collection as IPause[])
  const [pending, setPending] = useState(false)

  useEffect(() => {
    setList(collection as IPause[])
  }, [collection])

  const movePause = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const pause = list[dragIndex]
      const newList = R.reject(R.propEq('id', pause.id), list)
      newList.splice(hoverIndex, 0, pause)
      setList(newList)
    },
    [list],
  )

  const onSave = async () => {
    setPending(true)
    for (let i = 0; i < list.length; i++) {
      const pause = list[i]
      await updateDoc(getPausePath(pause.id), { ...pause, orderBy: i })
    }
    setPending(false)
    props.close()
  }

  if (!hasValue(collection)) {
    return null
  }

  return (
    <ModalWrapper
      isOpen={props.isOpen}
      title="Pause Order"
      pending={pending}
      actions={[
        { text: 'Save', onClick: onSave },
        { text: 'Cancel', colorScheme: 'gray', onClick: props.close },
      ]}
    >
      <DndProvider backend={HTML5Backend}>
        {list.map((pause: IPause, index: number) => (
          <DraggableRow
            key={pause.id}
            item={pause}
            index={index}
            move={movePause}
          />
        ))}
      </DndProvider>
    </ModalWrapper>
  )
}
