import { deleteDoc } from 'lib/api/connection'
import { getPauseTranscriptsPath, getPausePath } from '../models/IPause'
import { deleteCollection } from './deleteCollection'

/**
 * Delete a Pause document and its sub collections.
 *
 * @param pauseId The ID of the Pause document to delete.
 */
export const deletePause = async (pauseId: string): Promise<void> => {
  // delete transcripts collection
  const transcriptsPath = getPauseTranscriptsPath(pauseId)
  await deleteCollection(transcriptsPath)

  // delete Pause document
  const pausePath = getPausePath(pauseId)
  await deleteDoc(pausePath)
}
