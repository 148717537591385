import React from 'react'
import { SketchPicker, ColorResult } from 'react-color'

import './ColorPickerModal.css'

type Props = {
  open: boolean
  color: string
  onChange: (color: string) => void
}

export default function ColorPickerModal(props: Props) {
  const [color, setColor] = React.useState(props.color)

  const onChange = (result: ColorResult) => {
    const { rgb } = result
    const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    setColor(rgba)
    props.onChange(rgba)
  }

  if (!props.open) return null

  return <SketchPicker color={color} onChangeComplete={onChange} />
}
