export const milliSecondsToFriendlyTime = (ms: number, display100s = false) => {
  const milliseconds = `${parseInt(`${ms % 1000}`)}`.padStart(3, '0')
  const seconds: any = `${Math.floor((ms / 1000) % 60)}`.padStart(2, '0')
  const minutes: any = `${Math.floor((ms / (1000 * 60)) % 60)}`.padStart(2, '0')

  if (display100s) return minutes + ':' + seconds + '.' + milliseconds
  return minutes + ':' + seconds
}

// some constants
const secondMS = 1000
const minuteMS = secondMS * 60
const hourMS = minuteMS * 60

/**
 * Parse time string from a .srt file and return the value in milliseconds.
 *
 * @param srtTime The time in SRT format, e.g. 00:00:12,682 (HH:MM:SS,mmm)
 * @returns Time in milliseconds, e.g. 12682
 */
export const parseSRTTimeToMS = (srtTime: string): number => {
  // error check input
  if (!srtTime.match(/^\d\d:[0-5]\d:[0-5]\d,\d\d\d$/)) {
    throw new Error('SRT time string is not valid')
  }

  // parse and return millisecond value
  const parts = srtTime
    .replace(',', ':')
    .split(':')
    .map(n => parseInt(n))
  return (
    parts[0] * hourMS + parts[1] * minuteMS + parts[2] * secondMS + parts[3]
  )
}

/**
 * Generate .srt time string from the given milliseconds value.
 *
 * @param time Time in milliseconds, e.g. 12682
 * @returns The time in SRT format, e.g. 00:00:12,682 (HH:MM:SS,mmm)
 */
export const getSrtTimeFromMillis = (milliseconds: number): string => {
  // validate number range
  const minMillis = 0
  const maxMillis = 99 * hourMS + 59 * minuteMS + 59 * secondMS + 999
  if (milliseconds < minMillis || milliseconds > maxMillis) {
    throw new Error(
      `millisecond value must be >= ${minMillis} and <= ${maxMillis}`,
    )
  }

  // calculate parts
  const h = Math.floor(milliseconds / hourMS)
  const m = Math.floor((milliseconds / hourMS - h) * 60)
  const s = Math.floor(((milliseconds / hourMS - h) * 60 - m) * 60)
  const ms = milliseconds % secondMS

  // convert to "0" padded strings
  const hString = h.toString().padStart(2, '0')
  const mString = m.toString().padStart(2, '0')
  const sString = s.toString().padStart(2, '0')
  const msString = ms.toString().padStart(3, '0')

  // output full SRT time string from the parts
  return hString + ':' + mString + ':' + sString + ',' + msString
}
