import React from 'react'

import { List } from 'lib/components'
import { ISegment } from 'domains/segments/models/ISegment'
import SegmentPickerRow from 'domains/pauses/components/SegmentPickerRow'

interface IProps {
  path: string
  onSelect: (segment: ISegment) => any
}

export default function SegmentPickerList(props: IProps) {
  return (
    <List
      collection={props.path}
      RowComponent={SegmentPickerRow}
      rowProps={{ onSelect: props.onSelect }}
    />
  )
}
