import React from 'react'
import * as R from 'ramda'
import { Box, Spinner } from '@chakra-ui/react'

interface IProps {
  items: any[]
  RowComponent: any
  DividerComponent?: any
  keyExtractor?: (item: any) => string
  rowProps?: object
  pending?: boolean
}

function Divider() {
  return <Box ml={3} height="1px" bg="#dedede" />
}

export default function ListRaw(props: IProps) {
  const DividerComp = props.DividerComponent || Divider
  return (
    <Box>
      {props.pending && <Spinner />}
      {!props.pending &&
        props.items.map((item: any, index: number) => (
          <Box
            key={
              props.keyExtractor ? props.keyExtractor(item) : R.prop('id', item)
            }
          >
            <props.RowComponent {...item} {...(props.rowProps || {})} />
            {index !== props.items.length - 1 && <DividerComp />}
          </Box>
        ))}
    </Box>
  )
}
