import React from 'react'

export default function AudioDuration(props: {
  url?: string
  onDurationChanged: (duration: number) => any
}) {
  const audioRef = React.useRef<HTMLAudioElement>(null)

  const onLoadedMetadata = () => {
    if (audioRef.current?.duration) {
      const audioDuration = Math.ceil((audioRef.current?.duration || 0) * 1000)
      props.onDurationChanged(audioDuration)
    }
  }

  if (!props.url) return null

  return (
    <audio
      ref={audioRef}
      controls={true}
      onLoadedMetadata={onLoadedMetadata}
      className="hidden"
    >
      <source src={props.url} />
    </audio>
  )
}
