import React, { useState } from 'react'
import * as R from 'ramda'
import { Flex, Text, Icon } from '@chakra-ui/react'

import ModalWrapper from 'lib/components/ModalWrapper'
import SegmentPickerModal from 'domains/pauses/components/SegmentPickerModal'
import { ISegment, ISegmentType } from 'domains/segments/models/ISegment'
import SegmentEditorModal from 'domains/pauses/components/SegmentEditorModal'
import IPause from 'domains/pauses/models/IPause'

interface IOptionRowProps {
  label: string
  onClick: () => any
}
function OptionRow(props: IOptionRowProps) {
  return (
    <Flex
      ml={3}
      p={3}
      borderBottom="1px solid #efefef"
      direction="row"
      justify="space-between"
      align="center"
      cursor="pointer"
      onClick={props.onClick}
    >
      <Text fontSize="lg">{props.label}</Text>
      <Icon name="chevron-right" />
    </Flex>
  )
}

interface IProps {
  isOpen: boolean
  close: () => any
  onNewSegment: (segment: ISegment) => any
  pause: IPause
}

export default function NewSegmentModal(props: IProps) {
  const [addOrCreate, setAddOrCreate] = useState('')

  const onCreate = (type: ISegmentType | 'ADD_NEW_MEDIA' | 'ADD') => () =>
    setAddOrCreate(type)
  const onClose = () => setAddOrCreate('')

  const onNewSegment = (segment: ISegment) => {
    props.onNewSegment(segment)
    onClose()
  }

  if (addOrCreate === 'ADD') {
    return (
      <SegmentPickerModal
        isOpen={true}
        close={onClose}
        onNewSegment={onNewSegment}
      />
    )
  }

  if (
    R.contains(addOrCreate, [
      'CAPTION',
      'BREATHE_START',
      'BREATHE_IN',
      'BREATHE_OUT',
      'ADD_NEW_MEDIA',
    ])
  ) {
    return (
      <SegmentEditorModal
        isCreate={true}
        type={addOrCreate as ISegmentType}
        isOpen={true}
        close={onClose}
        onNewSegment={onNewSegment}
        pause={props.pause}
      />
    )
  }

  return (
    <ModalWrapper
      title={`Add Segment`}
      isOpen={props.isOpen}
      actions={[
        {
          text: 'Cancel',
          colorScheme: 'gray',
          onClick: props.close,
          extra: { type: 'button' },
        },
      ]}
      bodyPadding={'0'}
    >
      <OptionRow onClick={onCreate('CAPTION')} label="New Caption" />
      <OptionRow onClick={onCreate('BREATHE_START')} label="New Beathe Start" />
      <OptionRow onClick={onCreate('BREATHE_IN')} label="New Breathe In" />
      <OptionRow onClick={onCreate('BREATHE_OUT')} label="New Breathe Out" />
      <OptionRow onClick={onCreate('ADD_NEW_MEDIA')} label="Add New Media" />
      <OptionRow onClick={onCreate('ADD')} label="Add Existing Media" />
    </ModalWrapper>
  )
}
