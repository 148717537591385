import React, { useState } from 'react'
import * as R from 'ramda'
import { Box, Flex, Spinner, Text } from '@chakra-ui/react'

import Paper from 'lib/components/Paper'
import useDoc from 'lib/api/hooks/useDoc'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'
import IOrganization from 'domains/organization/models/IOrganization'
import useModalState from 'lib/api/hooks/useModalState'
import UpdateIntrosEditorModal from 'domains/organization/components/UpdateIntrosEditorModal'
import ListRaw from 'lib/components/ListRaw'

function IntroRow(props: { id: string; title: string; open: () => any }) {
  return (
    <Flex
      p={3}
      bg="white"
      direction="row"
      justify="space-between"
      align="center"
      cursor="pointer"
      onClick={props.open}
    >
      <Box>
        <Text fontSize="lg">{props.title}</Text>
      </Box>
    </Flex>
  )
}

export default function UpdatePauseIntros() {
  const [orgData] = useDoc(DEFAULT_ORG_PATH)
  const [isOpen, open, close] = useModalState()
  const [intro, setIntro] = useState('0')

  if (!orgData) {
    return <Spinner />
  }

  const org = orgData as IOrganization

  const onEdit = (introType: string) => () => {
    setIntro(introType)
    open()
  }

  const items = (R.keys(org.pauseIntroText) as string[]).map(
    (type: string) => ({
      id: type,
      title: type === '0' ? 'App Intro' : `${type} minute`,
      open: onEdit(type),
    }),
  )

  return (
    <Paper title="Intros">
      <ListRaw items={items} RowComponent={IntroRow} />
      <UpdateIntrosEditorModal isOpen={isOpen} close={close} intro={intro} />
    </Paper>
  )
}
