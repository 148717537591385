import React from 'react'

import FormErrorMessage from 'lib/components/FormErrorMessage'
import { FormControl, FormLabel } from '@chakra-ui/react'

type Option = { value: any; label: string }
interface IProps {
  label: string
  name: string
  onChange: any
  value: any
  options: Option[]
}

export default function FormSelect(props: IProps) {
  const labelBy = `label-${props.name}`

  return (
    <FormControl my={3} width="100%">
      <FormLabel id={labelBy} htmlFor={props.name}>
        {props.label}
      </FormLabel>
      <select
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
      >
        {props.options.map((option: Option, index: number) => (
          <option key={`${props.name}-option-${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <FormErrorMessage name={props.name} />
    </FormControl>
  )
}
