import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Box } from '@chakra-ui/react'

import IBookPractice, {
  getBookPracticePath,
} from 'domains/organization/models/IBookPractice'
import ModalWrapper from 'lib/components/ModalWrapper'
import FormInput from 'lib/components/FormInput'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'
import { addDoc, updateDoc } from 'lib/api/connection'

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  excerpt: Yup.string().required(),
})

interface IProps {
  isCreate?: boolean
  bookPractice?: IBookPractice
  close: () => any
}

export default function BookPracticeEditorModal(props: IProps) {
  const onSave = async (
    bookPractice: IBookPractice,
    bag: FormikHelpers<IBookPractice>,
  ) => {
    if (props.isCreate) {
      await addDoc(`${DEFAULT_ORG_PATH}/bookPractices`, {
        name: bookPractice.name,
        excerpt: bookPractice.excerpt,
        orderBy: bookPractice.orderBy,
      })
    } else {
      await updateDoc(getBookPracticePath(props.bookPractice!.id), {
        name: bookPractice.name,
        excerpt: bookPractice.excerpt,
      })
    }

    bag.setSubmitting(false)
    props.close()
  }

  return (
    <Formik
      initialValues={{
        id: '',
        name: props.isCreate ? '' : props.bookPractice!.name,
        excerpt: props.isCreate ? '' : props.bookPractice!.excerpt,
        orderBy: 9999,
      }}
      validationSchema={validationSchema}
      onSubmit={onSave}
    >
      {bag => (
        <ModalWrapper
          title={
            props.isCreate ? 'Create New Book Excerpt' : 'Edit Book Excerpt'
          }
          isOpen={true}
          pending={bag.isSubmitting}
          actions={[
            {
              text: 'Save',
              onClick: bag.handleSubmit,
              extra: { type: 'submit' },
            },
            {
              text: 'Cancel',
              colorScheme: 'gray',
              onClick: props.close,
              extra: { type: 'button' },
            },
          ]}
        >
          <Box>
            <FormInput
              label="Title"
              name="name"
              onChange={bag.handleChange}
              value={bag.values.name}
            />
            <FormInput
              label="Excerpt"
              name="excerpt"
              onChange={bag.handleChange}
              value={bag.values.excerpt}
              isTextArea={true}
              inputProps={{ height: '400px' }}
            />
          </Box>
        </ModalWrapper>
      )}
    </Formik>
  )
}
