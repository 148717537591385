import React from 'react'

export default function useModalState(
  defaultOpen = false,
): [boolean, () => void, () => void, () => void] {
  const [isOpen, setIsOpen] = React.useState<boolean>(defaultOpen)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)
  const toggle = () => setIsOpen(!isOpen)

  return [isOpen, openModal, closeModal, toggle]
}
