import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
} from '@chakra-ui/react'
import { Divider, Text } from '@chakra-ui/core'
import { DEFAULT_USERS_PATH } from 'lib/api/apiConstants'
import { getCollection } from 'lib/api/connection'
import Paper from 'lib/components/Paper'
import React, { useCallback, useEffect, useState } from 'react'
import { where } from 'firebase/firestore'
import { formatInTimeZone } from 'date-fns-tz'
import { IStreak } from '../models/IStreak'
import {
  getPauseHistory,
  refreshPauseHistoryStats,
  resetStreaks,
  restoreStreak,
} from 'lib/cloudFunctions/cloudFunctions'
import { IResetStreaksRequest } from 'lib/cloudFunctions/IResetStreaksRequest'
import { IRestoreStreakRequest } from 'lib/cloudFunctions/IRestoreStreakRequest'
import { IPauseHistoryWithCompletionDate } from '../models/IPauseHistory'

/**
 * Constants
 * -----------------------------------------------------------------------------
 */

const CURRENT_STREAK_CHAR = '✅'
const LONGEST_STREAK_CHAR = '😎'
const SYSTEM_PAUSES_CHAR = '🔩'

/**
 * UserDetailsScreen Component
 * -----------------------------------------------------------------------------
 */
export function UserDetailsScreen(props) {
  /**
   * State
   * ---------------------------------------------------------------------------
   */
  const [user, setUser] = useState<'loading' | any | null>('loading')
  const [pauseHistory, setPauseHistory] = useState<
    'loading' | IPauseHistoryWithCompletionDate[] | 'error'
  >('loading')
  const [streaks, setStreaks] = useState<'loading' | IStreak[] | 'error'>(
    'loading',
  )

  /**
   * Functions
   * ---------------------------------------------------------------------------
   */

  /** recalculate and reload user stats / streaks */
  async function refreshStreaks(userId) {
    setStreaks('loading')
    const response = await refreshPauseHistoryStats({
      userId,
      forceRecalc: true,
      addStreakMetadata: true,
    })
    if (!!response?.streaks) {
      setStreaks(response.streaks.reverse()) // sort descending by date
    } else {
      setStreaks('error')
    }
  }

  /** reload user data */
  const loadUserData = useCallback(async userId => {
    const users = await getCollection(DEFAULT_USERS_PATH, [
      where('id', '==', userId),
    ])
    if (!users || users.length !== 1) {
      setUser(null)
      throw new Error('user not found')
    }
    console.log('fetched user', users[0])
    setUser(users[0])
  }, [])

  /** reload pause history */
  async function loadPauseHistory(userId) {
    setPauseHistory('loading')
    const response = await getPauseHistory({
      userId,
      dayCount: 30, // last month's activity
    })
    setPauseHistory(!!response?.items ? response.items : 'error')
  }

  /** reload streaks, user data, and pause history */
  const reloadAll = useCallback(
    async userId => {
      try {
        await refreshStreaks(userId) // needs to be first, since it writes to DB
        await Promise.all([loadUserData(userId), loadPauseHistory(userId)]) // run in parallel
      } catch (error) {
        console.log('could not reload user data')
        console.error(error)
      }
    },
    [loadUserData],
  )

  /**
   * Lifecycle / Hooks
   * ---------------------------------------------------------------------------
   */

  useEffect(() => {
    // run once at startup when user ID is set
    const userId = props.match.params.id
    ;(async () => {
      await loadUserData(userId) // load user data first for faster UI display
      await reloadAll(userId)
    })()
  }, [props.match.params.id, loadUserData, reloadAll])

  /**
   * Render
   * ---------------------------------------------------------------------------
   */

  return (
    <Box p={3}>
      <Paper title={props.id}>
        <a href="/users">
          <Text color="blue.500">Back to users</Text>
        </a>
        {user === 'loading' ? (
          <p>Loading user data...</p>
        ) : !user ? (
          <p>No user found with id: {props.match.params.id}</p>
        ) : (
          <UserDetails
            user={user}
            userPauseHistory={pauseHistory}
            streaks={streaks}
            onRestoreStreak={async (request: IRestoreStreakRequest) => {
              await restoreStreak(request)
              await reloadAll(request.userId)
            }}
            onResetStreaks={async (request: IResetStreaksRequest) => {
              await resetStreaks(request)
              await reloadAll(request.userId)
            }}
          />
        )}
      </Paper>
    </Box>
  )
}

/**
 * Sub Component: UserDetails
 * -----------------------------------------------------------------------------
 * Display user details, pause history, streak history
 */
function UserDetails(props: {
  user: any
  userPauseHistory: 'loading' | IPauseHistoryWithCompletionDate[] | 'error'
  streaks: 'loading' | IStreak[] | 'error'
  onRestoreStreak: (request: IRestoreStreakRequest) => Promise<void>
  onResetStreaks: (request: IResetStreaksRequest) => Promise<void>
}) {
  /**
   * State / Data
   * ---------------------------------------------------------------------------
   */
  const { user, userPauseHistory, streaks } = props
  const [pendingAction, setPendingAction] = useState<{
    title: string
    body: JSX.Element
    request: IRestoreStreakRequest | IResetStreaksRequest
  } | null>(null)

  /**
   * Functions
   * ---------------------------------------------------------------------------
   */

  /**
   * Prepare to make the current streak current.
   */
  const onMakeCurrent = async streak => {
    const modalTitle = 'Make Streak Current'

    // do dry run to determine changes
    const request: IRestoreStreakRequest = {
      operation: 'restoreStreak',
      dryRun: true,
      userId: user.id,
      userTimezone: user.timezone,
      startDay: streak.firstDay,
      endDay: formatInTimeZone(new Date(), user.timezone, 'yyyy-MM-dd'),
    }
    setPendingAction({
      title: modalTitle,
      body: <CalculatingBox message="Calculating changes..." />,
      request,
    })
    const dryRunResults = await restoreStreak(request)

    setPendingAction({
      title: modalTitle,
      body: (
        <Box className="flex flex-col p-10px" rowGap="10px">
          <Text>
            Streak: {streak.firstDay} to {streak.lastDay}, {streak.length} days
          </Text>
          <Text>
            Day(s) to restore: {dryRunResults?.daysRestored.join(', ')}
          </Text>
          <Text fontWeight={'bold'}>
            Add {dryRunResults?.daysRestored.length} system pause(s) to make
            this streak current?
          </Text>
        </Box>
      ),
      request: { ...request, dryRun: false }, // when confirmed, do it for real
    })
  }

  /**
   * Prepare to join the selected streak with the previous streak.
   */
  const onJoinWithPrevious = async (selectedStreak, previousStreak) => {
    const modalTitle = 'Join Streaks'

    // do dry run to determine changes
    const request: IRestoreStreakRequest = {
      operation: 'restoreStreak',
      dryRun: true,
      userId: user.id,
      userTimezone: user.timezone,
      startDay: previousStreak.lastDay,
      endDay: selectedStreak.firstDay,
    }
    setPendingAction({
      title: modalTitle,
      body: <CalculatingBox message="Calculating changes..." />,
      request,
    })
    const dryRunResults = await restoreStreak(request)

    // set state
    setPendingAction({
      title: modalTitle,
      body: (
        <Box className="flex flex-col p-10px" rowGap="10px">
          <Text>
            Streak 1: {selectedStreak.firstDay} to {selectedStreak.lastDay},{' '}
            {selectedStreak.length} days
          </Text>
          <Text>
            Streak 2: {previousStreak.firstDay} to {previousStreak.lastDay},{' '}
            {previousStreak.length} days
          </Text>
          <Text>
            Day(s) to restore: {dryRunResults?.daysRestored.join(', ')}
          </Text>
          <Text fontWeight={'bold'}>
            Add {dryRunResults?.daysRestored.length} system pause(s) between the
            two streaks to join them?
          </Text>
        </Box>
      ),
      request: { ...request, dryRun: false }, // when confirmed, do it for real
    })
  }

  /**
   * Prepare to split the selected streak into two or more streaks.
   * All system pauses will be removed from the selected streak range.
   */
  const onSplit = async streak => {
    const modalTitle = 'Split Streak'

    // do dry run to determine changes
    const request: IResetStreaksRequest = {
      operation: 'resetStreaks',
      dryRun: true,
      userId: user.id,
      userTimezone: user.timezone,
      startDay: streak.firstDay,
      endDay: streak.lastDay,
    }
    setPendingAction({
      title: modalTitle,
      body: <CalculatingBox message="Calculating changes..." />,
      request,
    })
    const dryRunResults = await resetStreaks(request)

    // set state
    setPendingAction({
      title: modalTitle,
      body: (
        <Box className="flex flex-col p-10px" rowGap="10px">
          <Text>
            {' '}
            {dryRunResults?.daysDeleted.length} system pause(s) will be deleted,
            on days {dryRunResults?.daysDeleted.join(', ')}
          </Text>
          <Text fontWeight={'bold'}>
            Are you sure you want to split this streak?
          </Text>
        </Box>
      ),
      request: { ...request, dryRun: false }, // when confirmed, do it for real
    })
  }

  /**
   * Confirmation modal: "on confirm" action.
   * Perform the pending action streak request.
   */
  async function onConfirm() {
    if (!pendingAction) {
      return
    }
    const { request } = pendingAction
    if (request.operation === 'restoreStreak') {
      props.onRestoreStreak(request as IRestoreStreakRequest)
    } else if (request.operation === 'resetStreaks') {
      props.onResetStreaks(request as IResetStreaksRequest)
    }
    setPendingAction(null)
  }

  /**
   * Render
   * ---------------------------------------------------------------------------
   */
  return (
    <Flex p={6}>
      <Box paddingRight={6} w="75%">
        <Text fontSize="xl">
          {user.firstName} {user.lastName} - {user.email}
        </Text>

        <Text fontSize="sm">
          Last login: {getDateTime(user.lastLogin.seconds)}
        </Text>
        <Text fontSize="sm">
          Joined Pause on: {getDateTime(user.joinTimestamp.seconds)}
        </Text>
        <Text fontSize="sm">{user.timezone}</Text>
        <Divider />
        <Text fontWeight="bold">Device Details</Text>
        <Text>Device Name: "{user.deviceName}"</Text>
        <Text>OS Version: {user.deviceOSVersion}</Text>
        <Text>Platform: {JSON.stringify(user.platform)}</Text>
        <Text>
          Device Dimensions: {user.deviceWidth} x {user.deviceHeight}
        </Text>
        {!!user.streak && (
          <>
            <Divider />
            <Text fontWeight="bold">Streak Details</Text>
            <Text>Current Streak: {user.streak.currentStreakDaysInARow}</Text>
            <Text>Longest Streak: {user.streak.longestStreakDaysInARow}</Text>
            <Text>Total Pauses: {user.streak.total}</Text>
            <Text>
              Total Time:{' '}
              {new Date(user.streak.totalTime).toISOString().slice(11, 19)}
            </Text>
            <Text>Streak History</Text>
            <Box ml={6}>
              <Text>
                {`${CURRENT_STREAK_CHAR} = Current, ` +
                  `${LONGEST_STREAK_CHAR} = Longest, ` +
                  `${SYSTEM_PAUSES_CHAR} = Has System Pauses`}
              </Text>
              <Box
                overflowY={'scroll'}
                maxHeight={'200px'}
                marginTop={'5px'}
                maxWidth={'500px'}
              >
                {streaks === 'loading' ? (
                  <>
                    <Skeleton h="30px" my="10px" />
                    <Skeleton h="30px" my="10px" />
                    <Skeleton h="30px" my="10px" />
                    <Skeleton h="30px" my="10px" />
                    <Skeleton h="30px" my="10px" />
                  </>
                ) : streaks === 'error' ? (
                  <Text>Sorry, could not load streak history.</Text>
                ) : streaks.length === 0 ? (
                  <Text>None</Text>
                ) : (
                  streaks.map((streak, index) => (
                    <Streak
                      key={index}
                      streak={streak}
                      isFirst={index === 0}
                      isLast={index === streaks.length - 1}
                      onMakeCurrent={() => onMakeCurrent(streak)}
                      onJoinWithPrevious={() => {
                        const selectedStreak = streaks[index]
                        const previousStreak = streaks[index + 1]
                        onJoinWithPrevious(selectedStreak, previousStreak)
                      }}
                      onSplit={() => onSplit(streak)}
                    />
                  ))
                )}
              </Box>
            </Box>
          </>
        )}
        <Divider />
        <Text fontWeight="bold">App Details</Text>
        <Text>App Version: {user.mobileAppVersion}</Text>
        <Text>Mobile Release Channel: {user.mobileAppReleaseChannel}</Text>
        {user.signedInFromAnon && user.signedInFromAnon.length && (
          <>
            <Divider />
            <Text fontWeight="bold">
              Signed in from the following anonymous account(s):
            </Text>
            <ul>
              {user.signedInFromAnon.map((id, index) => (
                <li key={`${index}-${id}`}>
                  <a href={`/users/${id}`}>
                    <Text color="blue.500">{id}</Text>
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </Box>
      <Box w="25%">
        <Text fontWeight="bold">Pauses completed in the last 30 days:</Text>
        <Box overflowY={'scroll'} maxHeight={'600px'} paddingX={3}>
          {userPauseHistory === 'loading' ? (
            <>
              <Skeleton h="80px" p="16px" mb="16px" />
              <Skeleton h="80px" p="16px" mb="16px" />
              <Skeleton h="80px" p="16px" mb="16px" />
              <Skeleton h="80px" p="16px" mb="16px" />
              <Skeleton h="80px" p="16px" mb="16px" />
            </>
          ) : userPauseHistory === 'error' ? (
            <Text>Sorry, could not load pause history.</Text>
          ) : userPauseHistory.length === 0 ? (
            <Text>None</Text>
          ) : (
            userPauseHistory.map((item, index) => (
              <PauseHistoryItem key={`${index}-${item.pauseId}`} item={item} />
            ))
          )}
        </Box>
      </Box>
      <ConfirmationModal
        isOpen={!!pendingAction}
        title={pendingAction?.title || ''}
        body={pendingAction?.body || <></>}
        onClose={() => setPendingAction(null)}
        onConfirm={onConfirm}
      />
    </Flex>
  )
}

/**
 * Sub Component: Streak
 * -----------------------------------------------------------------------------
 * A single streak item in the streak history list.
 */
function Streak(props: {
  streak: IStreak
  isFirst: boolean
  isLast: boolean
  onMakeCurrent: VoidFunction
  onJoinWithPrevious: VoidFunction
  onSplit: VoidFunction
}) {
  const { streak, isFirst, isLast } = props
  const showMakeCurrent: boolean = isFirst && !streak.isCurrent
  const showJoinWithPrevious: boolean = !isLast
  const showSplit: boolean = !!streak.hasSystemPauses
  const showMenu: boolean = showMakeCurrent || showJoinWithPrevious || showSplit

  return (
    <Box
      className="flex flex-row space-x-2"
      justifyContent={'space-between'}
      borderRadius={'5px'}
      marginBottom={'10px'}
      padding={'5px'}
      background={'gray.50'}
    >
      <Text>
        {streak.firstDay} to {streak.lastDay}: {streak.length} day streak
      </Text>
      <Text>
        {(streak.isCurrent ? CURRENT_STREAK_CHAR : '') +
          (streak.isLongest ? LONGEST_STREAK_CHAR : '') +
          (streak.hasSystemPauses ? SYSTEM_PAUSES_CHAR : '')}
      </Text>
      {showMenu && (
        <Box marginLeft="auto">
          <Menu>
            <MenuButton as={Button} size="xs" disabled={false}>
              ...
            </MenuButton>
            <MenuList>
              {isFirst && !streak.isCurrent && (
                <MenuItem onClick={props.onMakeCurrent}>Make current</MenuItem>
              )}
              {!isLast && (
                <MenuItem onClick={props.onJoinWithPrevious}>
                  Join with previous
                </MenuItem>
              )}
              {streak.hasSystemPauses && (
                <MenuItem onClick={props.onSplit}>Split</MenuItem>
              )}
            </MenuList>
          </Menu>
        </Box>
      )}
    </Box>
  )
}

/**
 * Sub Component: PauseHistoryItem
 * -----------------------------------------------------------------------------
 * A single pause history item in the pause history list.
 */
function PauseHistoryItem(props: { item: IPauseHistoryWithCompletionDate }) {
  const { item } = props
  return (
    <Box
      background={'gray.50'}
      padding="1rem"
      borderRadius={'5px'}
      marginBottom="1rem"
      width={'100%'}
    >
      <Text fontWeight={'bold'}>{item.pauseTitle}</Text>
      <Text>Completed on: {item.completionDate}</Text>
    </Box>
  )
}

/**
 * Sub Component: ConfirmationModal
 * -----------------------------------------------------------------------------
 * Modal to confirm an action.
 */
function ConfirmationModal(props: {
  isOpen: boolean
  title: string
  body: JSX.Element
  onClose: VoidFunction
  onConfirm: VoidFunction
}) {
  const { isOpen, title, body, onClose, onConfirm } = props

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm}>
            Confirm
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

/**
 * Sub Component: CalculatingBox
 * -----------------------------------------------------------------------------
 */
const CalculatingBox = (props: { message: string }) => (
  <Box className="flex flex-col items-center p-10px" rowGap="10px">
    <Text>{props.message}</Text>
    <Spinner />
  </Box>
)

/**
 * Utility Functions
 * -----------------------------------------------------------------------------
 */

function getDateTime(seconds) {
  return new Date(seconds * 1000).toString()
}
