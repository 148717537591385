import React from 'react'
import clsx from 'clsx'
import { Box, Heading } from '@chakra-ui/react'
import { hasValue } from 'lib/utils'

interface IProps {
  title?: string
  actions?: any
  stickyTop?: boolean
  children: any
  className?: string
}

export default function Paper(props: IProps) {
  return (
    <Box
      rounded="lg"
      bg="white"
      py={4}
      px={6}
      className={clsx(
        { 'sticky top-16 shadow-lg z-50': props.stickyTop },
        props.className,
      )}
    >
      <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:justify-between md:items-center py-4">
        {hasValue(props.title) && (
          <Heading fontSize={['lg', '3xl']}>{props.title}</Heading>
        )}
        {hasValue(props.actions) && (
          <div className="flex flex-row md:justify-end items-center">
            {props.actions}
          </div>
        )}
      </div>

      {props.children}
    </Box>
  )
}
