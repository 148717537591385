import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react'

import { ISegment } from 'domains/segments/models/ISegment'
import ModalWrapper from 'lib/components/ModalWrapper'
import SegmentPickerList from 'domains/pauses/components/SegmentPickerList'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'

interface IProps {
  isOpen: boolean
  close: () => any
  onNewSegment: (segment: ISegment) => any
}

export default function SegmentPickerModal(props: IProps) {
  return (
    <ModalWrapper
      title={`Pick Segment`}
      isOpen={props.isOpen}
      actions={[
        {
          text: 'Cancel',
          colorScheme: 'gray',
          onClick: props.close,
          extra: { type: 'button' },
        },
      ]}
    >
      <Box>
        <Tabs variant="enclosed">
          <TabList>
            <Tab>Music</Tab>
            <Tab>Voice</Tab>
            <Tab>Images</Tab>
            <Tab>Videos</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <SegmentPickerList
                path={`${DEFAULT_ORG_PATH}/music_segments`}
                onSelect={props.onNewSegment}
              />
            </TabPanel>
            <TabPanel>
              <SegmentPickerList
                path={`${DEFAULT_ORG_PATH}/voice_segments`}
                onSelect={props.onNewSegment}
              />
            </TabPanel>
            <TabPanel>
              <SegmentPickerList
                path={`${DEFAULT_ORG_PATH}/image_segments`}
                onSelect={props.onNewSegment}
              />
            </TabPanel>
            <TabPanel>
              <SegmentPickerList
                path={`${DEFAULT_ORG_PATH}/video_segments`}
                onSelect={props.onNewSegment}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ModalWrapper>
  )
}
