import { httpsCallable } from 'firebase/functions'
import { functions } from 'lib/api/connection'
import {
  IRefreshPauseHistoryStatsRequest,
  IRefreshPauseHistoryStatsResponse,
} from './IRefreshPauseHistoryStatsRequest'
import {
  IGetPauseHistoryRequest,
  IGetPauseHistoryResponse,
} from './IGetPauseHistoryRequest'
import {
  IResetStreaksRequest,
  IResetStreaksResponse,
} from './IResetStreaksRequest'
import {
  IRestoreStreakRequest,
  IRestoreStreakResponse,
} from './IRestoreStreakRequest'

export async function refreshPauseHistoryStats(
  request: IRefreshPauseHistoryStatsRequest,
): Promise<IRefreshPauseHistoryStatsResponse | null> {
  return callCloudFunction<
    IRefreshPauseHistoryStatsRequest,
    IRefreshPauseHistoryStatsResponse
  >('streakFunctions-refreshPauseHistoryStats', request)
}

export async function getPauseHistory(
  request: IGetPauseHistoryRequest,
): Promise<IGetPauseHistoryResponse | null> {
  return callCloudFunction<IGetPauseHistoryRequest, IGetPauseHistoryResponse>(
    'streakFunctions-getPauseHistory',
    request,
  )
}

export async function restoreStreak(
  request: IRestoreStreakRequest,
): Promise<IRestoreStreakResponse | null> {
  return callCloudFunction<IRestoreStreakRequest, IRestoreStreakResponse>(
    'streakFunctions-adminStreaks',
    request,
  )
}

export async function resetStreaks(
  request: IResetStreaksRequest,
): Promise<IResetStreaksResponse | null> {
  return callCloudFunction<IResetStreaksRequest, IResetStreaksResponse>(
    'streakFunctions-adminStreaks',
    request,
  )
}

async function callCloudFunction<RequestType, ResponseType>(
  functionName: string,
  request: RequestType,
): Promise<ResponseType | null> {
  try {
    const callableFunction = httpsCallable(functions, functionName)
    const result = await callableFunction(request)
    const response = result.data as ResponseType
    console.log(`called cloud function (${functionName})`, request, response)
    return response
  } catch (error) {
    console.log(error)
    console.log(`error calling cloud function (${functionName}).`)
    return null
  }
}
