import {
  DEFAULT_ORG_PAUSES_PATH,
  DEFAULT_ORG_V2_PAUSES_PATH,
} from 'lib/api/apiConstants'

export interface IAudioRef {
  asset: string
  storageURI: string
  musicAsset?: string
  voicesAsset?: string
}

export default interface IPause {
  id: string
  title: string
  type: 1 | 3 | 5 | 10
  length: number
  orderBy: number
  published: boolean
  showTimeWhilePlaying?: boolean
  isBranched?: boolean
  branchPoint?: number
  branchPrompt?: string
  branches?: string[]
  pauseAudio?: IAudioRef[]
  processingAudio?: boolean
  processingAudioError?: string | null
  pauseAudioLastGenerated?: any
}

export const getPausePath = (id: string, isV2 = false) =>
  `${isV2 ? DEFAULT_ORG_V2_PAUSES_PATH : DEFAULT_ORG_PAUSES_PATH}/${id}`

export const getPauseSegmentsPath = (id: string) =>
  `${getPausePath(id)}/segments`
