import {
  getPauseSegmentsPath,
  getPausePath,
} from 'domains/pauses/models/IPause'
import { getSegmentPath } from 'domains/segments/models/ISegment'
import { deleteDoc, getCollection } from 'lib/api/connection'

export const deletePause = async (id: string) => {
  const segments = await getCollection<any>(getPauseSegmentsPath(id))
  if (segments) {
    for (const segment of segments) {
      await deleteDoc(getSegmentPath(id, segment.id))
    }
  }

  await deleteDoc(getPausePath(id))
}
