import React from 'react'
import { Formik, FormikHelpers, Form } from 'formik'
import * as Yup from 'yup'
import { Flex, Box, Spinner, Button, Image } from '@chakra-ui/react'

import { RouteComponentProps } from 'react-router'
import Paper from 'lib/components/Paper'
import FormInput from 'lib/components/FormInput'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from 'lib/api/connection'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, 'Login or Email must be at least 2 characters')
    .email()
    .required('Required'),
  password: Yup.string()
    .min(2, 'Password must be at least 2 characters')
    .required('Required'),
})

interface IFormValues {
  email: string
  password: string
}

export default function LoginScreen(props: RouteComponentProps) {
  const onLogin = async (
    values: IFormValues,
    bag: FormikHelpers<IFormValues>,
  ) => {
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password)
    } catch (e) {
      bag.setSubmitting(false)
    }
  }

  return (
    <div className="flex flex-col items-center bg-gray-100 px-4 p-8 md:pt-32 min-h-screen">
      <div className="w-72 flex flex-col items-center">
        <Paper
          title="Log In"
          actions={<Image src="/images/icon.png" width={16} height={16} />}
        >
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={loginSchema}
            onSubmit={onLogin}
          >
            {({ values, handleChange, isSubmitting }) => (
              <Form noValidate={true} data-test="login-form">
                <Box width={280} mt={5}>
                  <FormInput
                    value={values.email}
                    label="Email"
                    name="email"
                    inputProps={{ type: 'email' }}
                    onChange={handleChange}
                    placeholder="john@example.com"
                  />
                  <Box mb={4} />

                  <FormInput
                    value={values.password}
                    label="Password"
                    name="password"
                    inputProps={{ type: 'password' }}
                    onChange={handleChange}
                    placeholder="FluffyBunnies1982"
                  />
                  <Box mb={4} />

                  <Flex
                    width="100%"
                    direction="column"
                    justify="center"
                    align="center"
                  >
                    {isSubmitting && <Spinner />}
                    {!isSubmitting && (
                      <Button colorScheme="blue" type="submit">
                        Submit
                      </Button>
                    )}
                  </Flex>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </div>
    </div>
  )
}
