import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import ModalWrapper from 'lib/components/ModalWrapper'
import FormInput from 'lib/components/FormInput'
import {
  DEFAULT_ORG_PAUSES_PATH,
  DEFAULT_ORG_V2_PAUSES_PATH,
} from 'lib/api/apiConstants'
import { RouterContext } from 'domains/application/screens/AppScreen'
import { RouteComponentProps } from 'react-router'
import { addDoc } from 'lib/api/connection'

interface IProps {
  isOpen: boolean
  close: () => any
  version: 1 | 2
}

interface IFormValues {
  title: string
}

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Required')
    .min(2, 'Come on, give it a longer name'),
})

export default function CreatePauseModal(props: IProps) {
  const isV2 = props.version === 2
  const router = useContext(RouterContext) as RouteComponentProps

  const onSave = async (values: IFormValues) => {
    try {
      if (isV2) {
        const v2 = await addDoc<any>(DEFAULT_ORG_V2_PAUSES_PATH, {
          ...values,
          published: false,
        })
        router.history.push(`/v2pauses/${v2.id}`)
        return
      }
      const ref = await addDoc<any>(DEFAULT_ORG_PAUSES_PATH, {
        ...values,
        length: 60000,
        orderBy: 999,
        published: false,
        type: 1,
      })
      router.history.push(`/pauses/${ref.id}`)
    } catch (error) {
      console.error('Failed to create pause', { isV2, error }) // tslint:disable-line
    }
  }

  return (
    <Formik
      initialValues={{ title: '' }}
      onSubmit={onSave}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, submitForm }) => (
        <ModalWrapper
          title="New Pause"
          isOpen={props.isOpen}
          actions={[
            {
              text: 'Create',
              colorScheme: 'green',
              onClick: submitForm,
              extra: { type: 'submit' },
            },
            {
              text: 'Cancel',
              colorScheme: 'gray',
              onClick: props.close,
              extra: { type: 'button' },
            },
          ]}
        >
          <Form>
            <FormInput
              label="Title"
              name="title"
              value={values.title}
              onChange={handleChange}
              placeholder="Daily Pause"
            />
          </Form>
        </ModalWrapper>
      )}
    </Formik>
  )
}
