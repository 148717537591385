import { orderBy } from 'firebase/firestore'

import useDoc from 'lib/api/hooks/useDoc'
import {} from '@/pauses/models/IPause'
import useCollection from 'lib/api/hooks/useCollection'
import IProgram, {
  getProgramDaysPath,
  getProgramPath,
  IProgramDay,
} from '@/pauses/models/IProgram'

export default function useProgram(
  id: string,
): {
  program: {
    data: IProgram | null
    loading: boolean
    update: (updates: Partial<IProgram>) => any
  }
  days: {
    data: IProgramDay[]
    loading: boolean
    refetch: () => any
  }
} {
  const [program, programLoading, updateProgram] = useDoc<IProgram>(
    getProgramPath(id),
  )
  const [days, daysLoading, refetch] = useCollection<IProgramDay>(
    getProgramDaysPath(id),
    [orderBy('order', 'asc')],
  )

  return {
    program: { data: program, loading: programLoading, update: updateProgram },
    days: {
      data: days,
      loading: daysLoading,
      refetch,
    },
  }
}
