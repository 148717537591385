import React, { useState } from 'react'
import * as R from 'ramda'
import { FormikHelpers, Formik, Form } from 'formik'
import * as Yup from 'yup'
import uuid from 'uuid/v4'

import {
  ISegment,
  isTextSegment,
  isBreathSegment,
  mockSegment,
  ISegmentType,
  isMediaSegment,
} from 'domains/segments/models/ISegment'
import IPause, { getPausePath } from 'domains/pauses/models/IPause'
import useDoc from 'lib/api/hooks/useDoc'
import ModalWrapper from 'lib/components/ModalWrapper'
import FormInput from 'lib/components/FormInput'
import ColorPickerModal from 'lib/components/ColorPickerModal'
import colors from 'lib/styles/colors'
import FileInput from 'lib/components/FileInput'
import { getFileExtension } from 'lib/utils/files'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'
import { cascadeSegmentTimes } from 'domains/segments/workflows/cascadeSegmentTimes'
import {
  Spinner,
  Flex,
  Text,
  Box,
  FormControl,
  FormLabel,
  Progress,
  Checkbox,
} from '@chakra-ui/react'
import FormErrorMessage from 'lib/components/FormErrorMessage'
import { getSegmentStorageDir, uploadFile, addDoc } from 'lib/api/connection'

interface IFormValues extends ISegment {
  cascade?: boolean
  cascadeBranch?: boolean
}

interface IProps {
  isOpen: boolean
  isCreate?: boolean
  type?: ISegmentType
  close: () => any
  segment?: ISegment
  pause: IPause
  onNewSegment?: (segment: ISegment) => any
}

const getValidationSchema = (pause: IPause, isCreate: boolean) =>
  Yup.object().shape({
    type: Yup.mixed().notOneOf(['ADD_NEW_MEDIA'], 'Select media type'),
    start: Yup.number()
      .required('Required')
      .lessThan(pause.length + 1, 'Cannot exceed pause length'),
    end: Yup.number()
      .required('Required')
      .lessThan(pause.length + 1, 'Cannot exceed pause length')
      .moreThan(Yup.ref('start'), 'End has to be after start'),
    asset: Yup.mixed().when(['type, asset'], {
      is: type => isCreate && isMediaSegment(type),
      then: Yup.mixed().required('Select media'),
      otherwise: Yup.mixed(),
    }),
    name: Yup.string().when(['type'], {
      is: type => isCreate && isMediaSegment(type),
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
  })

export default function SegmentEditorModal(props: IProps) {
  const [progress, setProgress] = useState(0)

  const segmentPath = `${getPausePath(props.pause.id)}/segments/${R.propOr(
    '_',
    'id',
    props.segment,
  )}`
  const [data, , updateSegment] = useDoc(segmentPath)

  const addNewMedia = async (
    values: IFormValues,
    bag: FormikHelpers<ISegment>,
  ) => {
    const file = values.asset
    const filename = `${uuid()}${getFileExtension(file.name)}`
    const storageUrl = `${getSegmentStorageDir(values.type)}/${filename}`
    const asset = await uploadFile(storageUrl, file, setProgress)
    bag.setSubmitting(false)
    if (asset) {
      const newSegment = {
        asset,
        ...R.pick(['start', 'end', 'type', 'name'], values),
      } as ISegment

      await addDoc(
        `${DEFAULT_ORG_PATH}/${newSegment.type.toLowerCase()}_segments`,
        R.pick(['name', 'type', 'asset'], newSegment),
      )
      props.onNewSegment!(newSegment)
    }
  }

  const onSave = async (
    values: IFormValues,
    bag: FormikHelpers<IFormValues>,
  ) => {
    if (props.isCreate) {
      let newSegment: ISegment = R.compose(
        R.dissoc('cascade'),
        R.dissoc('cascadeBranch'),
      )(values)
      if (isMediaSegment(values.type)) {
        await addNewMedia(values, bag)
      } else {
        switch (props.type!) {
          case 'CAPTION':
          case 'IMAGE':
          case 'MUSIC':
          case 'VOICE':
          case 'VIDEO':
            newSegment = R.pick(
              ['asset', 'start', 'end', 'type'],
              values,
            ) as ISegment
            break
          default:
            newSegment = R.dissoc('id', values)
            break
        }
        bag.setSubmitting(false)
        props.onNewSegment!(newSegment)
      }
    } else {
      if (values.cascade) {
        await cascadeSegmentTimes(
          props.pause.id,
          props.segment!.id,
          values.end,
          values.cascadeBranch ? props.segment!.branch || 0 : undefined,
        )
      }
      await updateSegment(values)
      bag.setSubmitting(false)
      props.close()
    }
  }

  if (!props.isCreate && data === null) {
    return <Spinner />
  }

  const segment: IFormValues = props.isCreate
    ? mockSegment({
        type: props.type,
        start: 0,
        end: 1000,
        breatheColor: colors.primary,
      })
    : {
        ...(data as ISegment),
        cascade: false,
      }

  return (
    <Formik
      initialValues={segment}
      onSubmit={onSave}
      validationSchema={getValidationSchema(props.pause, !!props.isCreate)}
    >
      {({
        values,
        setFieldValue,
        errors,
        handleChange,
        submitForm,
        isSubmitting,
      }) => {
        const onColorChange = (color: string) =>
          setFieldValue('breatheColor', color)

        const onFilePicked = (e: React.FormEvent<HTMLInputElement>) => {
          if (e.currentTarget.files) {
            const file = e.currentTarget.files[0]
            setFieldValue('asset', file)
            setFieldValue('name', file.name)
          }
        }

        const segmentBranch = R.pathOr(0, ['segment', 'branch'], props)
        const cascading =
          !props.isCreate && values.end !== (data as ISegment).end ? (
            <Box>
              <Checkbox
                isChecked={values.cascade}
                onChange={handleChange}
                name="cascade"
              >
                Move succeeding segments by{' '}
                {values.end - (data as ISegment).end} ms
              </Checkbox>
              {props.pause.isBranched && (
                <Checkbox
                  isChecked={values.cascadeBranch || false}
                  onChange={handleChange}
                  name="cascadeBranch"
                >
                  Only move segments on "
                  {R.pathOr(
                    'Pre-Branch Segments',
                    ['pause', 'branches', segmentBranch - 1],
                    props,
                  )}
                  " branch?
                </Checkbox>
              )}
            </Box>
          ) : null

        return (
          <ModalWrapper
            title={`Edit ${segment.type}`}
            isOpen={props.isOpen}
            pending={isSubmitting}
            actions={[
              {
                text: 'Save',
                onClick: submitForm,
                extra: { type: 'submit' },
              },
              {
                text: 'Cancel',
                colorScheme: 'gray',
                onClick: props.close,
                extra: { type: 'button' },
              },
            ]}
          >
            {isSubmitting &&
              props.isCreate &&
              segment.type === 'ADD_NEW_MEDIA' && (
                <Flex direction="row" align="center" py={3}>
                  <Box flex={1}>
                    <Progress
                      value={progress}
                      hasStripe={true}
                      isAnimated={true}
                    />
                  </Box>
                  <Text ml={1}>{progress}%</Text>
                </Flex>
              )}
            <Form>
              {props.isCreate && (
                <Flex
                  direction="row"
                  justify="space-between"
                  align="flex-start"
                >
                  {segment.type === 'ADD_NEW_MEDIA' && (
                    <Box>
                      <select
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                      >
                        <option value="ADD_NEW_MEDIA">Select Media Type</option>
                        <option value="IMAGE">Image</option>
                        <option value="VOICE">Voice</option>
                        <option value="MUSIC">Music</option>
                        <option value="VIDEO">Video</option>
                      </select>
                      <FormErrorMessage name="type" />
                    </Box>
                  )}

                  {isMediaSegment(values.type) && (
                    <Box>
                      <FileInput
                        name="asset"
                        text="Browse"
                        onChange={onFilePicked}
                      />
                      <FormErrorMessage name="asset" />
                    </Box>
                  )}
                </Flex>
              )}

              {isMediaSegment(values.type) && (
                <FormInput
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Some cool sound clip"
                />
              )}

              <Flex direction="row" justify="space-between" align="flex-start">
                <FormInput
                  label="Start (ms)"
                  name="start"
                  value={values.start}
                  onChange={handleChange}
                  placeholder="3000"
                  inputProps={{ type: 'number' }}
                />
                <FormInput
                  label="End (ms)"
                  name="end"
                  value={values.end}
                  onChange={handleChange}
                  placeholder="3000"
                  inputProps={{ type: 'number' }}
                />
              </Flex>

              {cascading}
              {isBreathSegment(segment.type) && (
                <Box>
                  {segment.type !== 'BREATHE_START' && (
                    <FormInput
                      label="Breathe Animation Length (ms)"
                      name="breatheLength"
                      value={values.breatheLength}
                      onChange={handleChange}
                      placeholder="3000"
                      inputProps={{ type: 'number' }}
                    />
                  )}

                  <FormInput
                    label="Breathe Message"
                    name="breathMessage"
                    value={values.breathMessage}
                    onChange={handleChange}
                    isTextArea={true}
                  />

                  <FormControl my={3}>
                    <FormLabel id="color" htmlFor="color">
                      Breathe Color
                    </FormLabel>
                    <ColorPickerModal
                      color={values.breatheColor!}
                      open={true}
                      onChange={onColorChange}
                    />
                  </FormControl>
                </Box>
              )}

              {isTextSegment(segment.type) && (
                <FormInput
                  label="Caption"
                  name="asset"
                  value={values.asset}
                  onChange={handleChange}
                  isTextArea={true}
                  inputProps={{
                    rows: 5,
                  }}
                />
              )}
            </Form>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
