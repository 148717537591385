import React from 'react'

import { ISegmentType } from 'domains/segments/models/ISegment'
import { getSegmentStorageDir } from 'lib/api/connection'
import GenericUploadFileInput from 'lib/components/GenericUploadFileInput'

interface IProps {
  type: ISegmentType
  onUploaded: (path: string) => any
}

export default function FileUploadInput(props: IProps) {
  return (
    <GenericUploadFileInput
      baseFilePath={getSegmentStorageDir(props.type)}
      onUploaded={props.onUploaded}
      accept={
        props.type === 'VIDEO'
          ? 'video/*'
          : props.type === 'VOICE' || props.type === 'MUSIC'
          ? 'audio/*'
          : ''
      }
    />
  )
}
