import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'

export default interface IBookPractice {
  id: string
  name: string
  excerpt: string
  orderBy: number
}

export const getBookPracticePath = (id: string) =>
  `${DEFAULT_ORG_PATH}/bookPractices/${id}`
