import * as R from 'ramda'

import { IOrgAnnouncement } from 'domains/organization/models/IOrganization'
import useDoc from 'lib/api/hooks/useDoc'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'

export default function useAnnouncement(): [IOrgAnnouncement | null, boolean] {
  const [org] = useDoc(DEFAULT_ORG_PATH)
  if (!org) {
    return [null, true]
  }

  const announcement = {
    version: R.pathOr('default', ['announcement', 'version'], org),
    visible: R.pathOr(false, ['announcement', 'visible'], org),
    adminVisible: R.pathOr(false, ['announcement', 'adminVisible'], org),
    showBadge: R.pathOr(false, ['announcement', 'showBadge'], org),
    type: R.pathOr('HomeBottomSheet', ['announcement', 'type'], org),
    bannerTitle: R.pathOr('', ['announcement', 'bannerTitle'], org),
    bannerSubtitle: R.pathOr('', ['announcement', 'bannerSubtitle'], org),
    betaBannerTitle: R.pathOr('', ['announcement', 'betaBannerTitle'], org),
    betaBannerSubtitle: R.pathOr(
      '',
      ['announcement', 'betaBannerSubtitle'],
      org,
    ),
    title: R.pathOr('', ['announcement', 'title'], org),
    subtitle: R.pathOr('', ['announcement', 'subtitle'], org),
    titleImage: R.pathOr('', ['announcement', 'titleImage'], org),
    video: R.pathOr('', ['announcement', 'video'], org),
    videoThumbnail: R.pathOr('', ['announcement', 'videoThumbnail'], org),
    body: R.pathOr('', ['announcement', 'body'], org),
    showBuyBook: R.pathOr(false, ['announcement', 'showBuyBook'], org),
    shareLink: R.pathOr('', ['announcement', 'shareLink'], org),
    ctaLabel: R.pathOr('', ['announcement', 'ctaLabel'], org),
    ctaLink: R.pathOr('', ['announcement', 'ctaLink'], org),
    ctaIconName: R.pathOr('', ['announcement', 'ctaIconName'], org),
    ctaIconLibrary: R.pathOr('', ['announcement', 'ctaIconLibrary'], org),
  } as IOrgAnnouncement

  return [announcement, false]
}
