import React, { useState } from 'react'
import * as R from 'ramda'
import { FormikHelpers, Formik } from 'formik'
import * as Yup from 'yup'
import uuid from 'uuid/v4'
import { Flex, Text, Box, Progress } from '@chakra-ui/react'

import FileInput from 'lib/components/FileInput'
import { getFileExtension } from 'lib/utils/files'
import FormErrorMessage from 'lib/components/FormErrorMessage'
import { uploadFile } from 'lib/api/connection'

interface IProps {
  baseFilePath: string
  onUploaded: (path: string) => any
  accept?: string
}

const validationSchema = Yup.object().shape({
  file: Yup.mixed().required(),
})

export default function GenericUploadFileInput(props: IProps) {
  const [progress, setProgress] = useState(0)

  const onSave = async (values: any, bag: FormikHelpers<any>) => {
    bag.setSubmitting(true)
    const file = values.file
    const filename = `${uuid()}${getFileExtension(file.name)}`
    const storageUrl = `${props.baseFilePath}/${filename}`
    const asset = await uploadFile(storageUrl, file, setProgress)
    bag.setSubmitting(false)
    if (asset) props.onUploaded(asset)
  }

  return (
    <Formik
      initialValues={{ file: null }}
      onSubmit={onSave}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, submitForm }) => {
        const onFilePicked = (e: React.FormEvent<HTMLInputElement>) => {
          if (e.currentTarget.files) {
            const file = e.currentTarget.files[0]
            setFieldValue('file', file)
            setTimeout(submitForm, 1000)
          }
        }

        return (
          <Box width="100%">
            <Flex direction="row" justify="space-between">
              <Box>
                <FileInput
                  name="file"
                  text="Browse"
                  onChange={onFilePicked}
                  accept={props.accept}
                />
                <Text>
                  {R.pathOr('No file selected', ['file', 'name'], values)}
                </Text>
                <FormErrorMessage name="asset" />
              </Box>
            </Flex>

            {progress > 0 && progress < 100 && (
              <Flex direction="row" align="center" py={2}>
                <Box flex={1}>
                  <Progress
                    value={progress}
                    hasStripe={true}
                    isAnimated={true}
                  />
                </Box>
                <Text ml={1}>{progress}%</Text>
              </Flex>
            )}
          </Box>
        )
      }}
    </Formik>
  )
}
