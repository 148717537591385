import React, { useState } from 'react'
import { Formik, FormikHelpers, Form } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import uuid from 'uuid/v4'
import * as R from 'ramda'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  Box,
  Flex,
  Button,
  Checkbox,
  Icon,
  Text,
} from '@chakra-ui/react'
import { RouteComponentProps } from 'react-router'

import { getNavigationProp } from 'lib/utils/withNavigationProps'
import useDoc from 'lib/api/hooks/useDoc'
import IPause, { getPausePath } from 'domains/pauses/models/IPause'
import FormInput from 'lib/components/FormInput'
import Dialog from 'lib/components/Dialog'
import { hasValue } from 'lib/utils'
import PauseSegments from 'domains/pauses/components/PauseSegments'
import { deletePause } from 'domains/pauses/workflows/deletePause'
import Paper from 'lib/components/Paper'
import useModalState from 'lib/api/hooks/useModalState'
import GenerateManifestModal from 'domains/pauses/components/GenerateManifestModal'
import { formatForDisplay } from 'lib/utils/date'
import FormSelect from 'lib/components/FormSelect'

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'That is a pretty short title...')
    .required('Required'),
  branchPoint: Yup.number().when(['isBranched'], {
    is: R.identity,
    then: Yup.number().required('Required'),
    otherwise: Yup.number(),
  }),
  branches: Yup.array().when(['isBranched'], {
    is: R.identity,
    then: Yup.array()
      .of(Yup.string().min(2, 'Must be at least 2 characters'))
      .required('Required'),
    otherwise: Yup.array(),
  }),
})

export default function PauseDetailsScreen(props: RouteComponentProps) {
  const [deleteOpen, setDeleteOpen] = useState(false)

  const pauseId = getNavigationProp(props, 'id') as string
  const pausePath = getPausePath(pauseId)
  const [data, , updatePause] = useDoc(pausePath)

  const [
    generateManifestModalOpen,
    ,
    closeGenerateManifestModal,
  ] = useModalState()
  const [
    generateAudioModalOpen,
    openGenerateAudioModal,
    closeGenerateAudioModal,
  ] = useModalState()

  if (data === null) {
    return <Spinner />
  }

  const pause = data as IPause

  const onSave = async (values: IPause, bag: FormikHelpers<IPause>) => {
    await updatePause(values)
    toast.success('Pause details saved!')
    bag.resetForm()
  }

  const onDelete = () => setDeleteOpen(true)
  const onCloseDelete = () => setDeleteOpen(false)
  const onDeleteConfirmed = async () => {
    await deletePause(pauseId)
    props.history.goBack()
  }

  const onGenerateAudioConfirmed = async () => {
    updatePause({
      version: uuid(),
      processingAudio: true,
    })
    closeGenerateAudioModal()
  }

  if (!hasValue(data)) {
    return <Spinner />
  }

  return (
    <Formik
      initialValues={pause}
      onSubmit={onSave}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {formikProps => {
        const onBranchChanged = () => {
          if (formikProps.values.isBranched) {
            formikProps.setFieldValue('isBranched', false)
          } else {
            const branches = formikProps.values.branches || ['']
            formikProps.setFieldValue('isBranched', true)
            formikProps.setFieldValue(
              'branchPoint',
              formikProps.values.length / 2,
            )
            formikProps.setFieldValue('branches', branches)
          }
        }
        const onAddBranch = () =>
          formikProps.setFieldValue('branches', [
            ...(formikProps.values.branches || []),
            '',
          ])
        const onDeleteBranch = index => () =>
          formikProps.setFieldValue(
            'branches',
            R.remove(index, 1, formikProps.values.branches || []),
          )

        return (
          <Box p={3}>
            <Form>
              <Paper title={formikProps.values.title}>
                <Flex direction="row" justify="flex-end" align="center" my={3}>
                  <Button
                    colorScheme="green"
                    type="submit"
                    mr={2}
                    isLoading={formikProps.isSubmitting}
                    isDisabled={!formikProps.dirty}
                  >
                    Save
                  </Button>
                  {/* <Button
                    colorScheme="blue"
                    type="button"
                    mr={2}
                    onClick={openGenerateManifestModal}
                  >
                    Generate Manifest
                  </Button> */}
                  <Button
                    colorScheme="blue"
                    type="button"
                    mr={2}
                    onClick={openGenerateAudioModal}
                    isLoading={pause.processingAudio}
                    loadingText="Generating Audio..."
                  >
                    Generate Audio
                  </Button>
                  <Button
                    colorScheme="red"
                    type="button"
                    onClick={onDelete}
                    mr={2}
                    isLoading={formikProps.isSubmitting}
                  >
                    Delete
                  </Button>
                </Flex>

                <Box ml={2}>
                  <FormInput
                    label="Title"
                    name="title"
                    onChange={formikProps.handleChange}
                    value={formikProps.values.title}
                  />

                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr"
                    gridColumnGap={3}
                  >
                    <FormSelect
                      label="Type (Because one minute may not actually be ONE minute)"
                      name="type"
                      value={formikProps.values.type}
                      onChange={formikProps.handleChange}
                      options={[
                        { value: 1, label: '1 Minute' },
                        { value: 3, label: '3 Minute' },
                        { value: 5, label: '5 Minute' },
                        { value: 10, label: '10 Minute' },
                      ]}
                    />

                    <FormInput
                      label="Pause Length (ms)"
                      name="length"
                      value={formikProps.values.length}
                      onChange={formikProps.handleChange}
                      placeholder="3000"
                      inputProps={{ type: 'number' }}
                    />
                  </Box>

                  <Box my={3}>
                    <Checkbox
                      isChecked={formikProps.values.published}
                      onChange={formikProps.handleChange}
                      name="published"
                    >
                      Published
                    </Checkbox>
                  </Box>

                  <Box my={3}>
                    <Checkbox
                      isChecked={
                        formikProps.values.showTimeWhilePlaying || false
                      }
                      onChange={formikProps.handleChange}
                      name="showTimeWhilePlaying"
                    >
                      Show Timestamp on Progress Bar
                    </Checkbox>
                  </Box>

                  <Checkbox
                    isChecked={formikProps.values.isBranched || false}
                    onChange={onBranchChanged}
                    name="isBranched"
                  >
                    Has a branching segment
                  </Checkbox>

                  {formikProps.values.isBranched && (
                    <Box>
                      <Box
                        display="grid"
                        gridTemplateColumns="1fr 1fr"
                        gridColumnGap={3}
                      >
                        <FormInput
                          label="Branch Prompt"
                          name="branchPrompt"
                          value={formikProps.values.branchPrompt || ''}
                          onChange={formikProps.handleChange}
                          placeholder="what do you need more of?"
                        />
                        <FormInput
                          label="Present Branch at Time (ms)"
                          name="branchPoint"
                          value={formikProps.values.branchPoint || 0}
                          onChange={formikProps.handleChange}
                          placeholder="3000"
                          inputProps={{ type: 'number' }}
                        />
                      </Box>
                      {(formikProps.values.branches || []).map(
                        (branchName: string, index: number) => (
                          <Flex
                            key={`branch-${index + 1}`}
                            direction="row"
                            align="flex-end"
                          >
                            <Box flex={1}>
                              <FormInput
                                label={`Branch ${index + 1}`}
                                name={`branches[${index}]`}
                                onChange={formikProps.handleChange}
                                placeholder="Let it all go"
                                value={formikProps.values.branches![index]}
                              />
                            </Box>
                            <Box
                              onClick={onDeleteBranch(index)}
                              cursor="pointer"
                              ml={2}
                              mb={4}
                            >
                              <Icon name="delete" />
                            </Box>
                          </Flex>
                        ),
                      )}

                      <Button colorScheme="blue" onClick={onAddBranch}>
                        Add Branch
                      </Button>
                    </Box>
                  )}
                </Box>

                {!formikProps.values.processingAudio && (
                  <Box m={2}>
                    {hasValue(formikProps.values.processingAudioError) && (
                      <Box mt={3}>
                        <Text fontSize="lg">Error generating audio</Text>
                        <Text>{formikProps.values.processingAudioError}</Text>
                      </Box>
                    )}

                    {hasValue(formikProps.values.pauseAudio) && (
                      <Box mt={3}>
                        <Text fontSize="lg">Audio Files</Text>
                        <Text fontSize="small">
                          Last generated:{' '}
                          {formikProps.values.pauseAudioLastGenerated
                            ? formatForDisplay(
                                formikProps.values.pauseAudioLastGenerated,
                              )
                            : 'N/A'}
                        </Text>

                        {formikProps.values.pauseAudio!.map((audio, index) => (
                          <Box key={`pause-audio-${index}`} my={2}>
                            {index === 0 && (
                              <Text>
                                {pause.isBranched ? 'Pre-Branch Audio' : ''}
                              </Text>
                            )}
                            {index !== 0 && <Text>Branch {index} Audio</Text>}

                            <Flex direction="row" wrap="wrap">
                              <div>
                                <Text fontSize={11}>Combined</Text>
                                <audio controls={true}>
                                  <source src={audio.asset} />
                                </audio>
                              </div>

                              {hasValue(audio.musicAsset) && (
                                <div>
                                  <Text fontSize={11}>Music Only</Text>
                                  <audio controls={true}>
                                    <source src={audio.musicAsset} />
                                  </audio>
                                </div>
                              )}
                              {hasValue(audio.voicesAsset) && (
                                <div>
                                  <Text fontSize={11}>Voice Only</Text>
                                  <audio controls={true}>
                                    <source src={audio.voicesAsset} />
                                  </audio>
                                </div>
                              )}
                            </Flex>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                )}
              </Paper>
            </Form>

            <Box mt={3} />
            <Paper title="Segments">
              {formikProps.values.isBranched && (
                <Tabs>
                  <TabList>
                    <Tab>Pre-Branch Segments</Tab>
                    {(formikProps.values.branches || []).map(
                      (branchName, index) => (
                        <Tab key={`branch-tab-${index}`}>
                          "{branchName}" Segments
                        </Tab>
                      ),
                    )}
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <PauseSegments pause={pause} branch={0} />
                    </TabPanel>
                    {(formikProps.values.branches || []).map((b, index) => (
                      <TabPanel key={`branch-tab-panel-${index}`}>
                        <PauseSegments pause={pause} branch={index + 1} />
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              )}
              {!formikProps.values.isBranched && (
                <PauseSegments pause={pause} />
              )}
            </Paper>

            <Dialog
              isOpen={deleteOpen}
              message="Are you sure you want to delete this pause?"
              onConfirm={onDeleteConfirmed}
              onDeny={onCloseDelete}
              icon="delete"
              doubleConfirm={true}
            />

            <Dialog
              isOpen={generateAudioModalOpen}
              message="Are you sure you want to generate new audio? This can take up to 10 minutes depending on the amount of audio segments..."
              onConfirm={onGenerateAudioConfirmed}
              onDeny={closeGenerateAudioModal}
              icon="spinner"
            />

            {generateManifestModalOpen && (
              <GenerateManifestModal
                pauseId={pauseId}
                isOpen={generateManifestModalOpen}
                close={closeGenerateManifestModal}
              />
            )}
          </Box>
        )
      }}
    </Formik>
  )
}
