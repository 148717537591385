import React from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'
import { RecoilRoot } from 'recoil'
import { ToastContainer } from 'react-toastify'
import { ChakraProvider } from '@chakra-ui/react'
import 'react-toastify/dist/ReactToastify.css'
import '@reach/dialog/styles.css'

import 'lib/styles/css/index.css'
import 'lib/styles/css/modals.css'
import StartupScreen from 'domains/application/screens/StartupScreen'

ReactModal.setAppElement('#modals')

ReactDOM.render(
  <RecoilRoot>
    <ChakraProvider>
      <StartupScreen />
      <ToastContainer closeOnClick={true} hideProgressBar={true} />
    </ChakraProvider>
  </RecoilRoot>,
  document.getElementById('root'),
)
