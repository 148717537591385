import React from 'react'
import {
  Box,
  Button,
  Heading,
  ListItem,
  SimpleGrid,
  UnorderedList,
} from '@chakra-ui/react'
import Paper from 'lib/components/Paper'
import { Formik, FormikHelpers } from 'formik'
import FormInput from 'lib/components/FormInput'
import * as Yup from 'yup'
import IOrganization, { IOrgForceUpgrade } from '../models/IOrganization'
import useDoc from 'lib/api/hooks/useDoc'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'
import { toast } from 'react-toastify'
import * as semver from 'semver'

/**
 * Component
 * -----------------------------------------------------------------------------
 */

export default function OrgForceUpgrade() {
  const [orgData, , updateOrg] = useDoc<IOrganization>(DEFAULT_ORG_PATH)

  const onSave = async (
    values: IOrgForceUpgrade,
    bag: FormikHelpers<IOrgForceUpgrade>,
  ) => {
    if (!!(await updateOrg({ forceUpgrade: values }))) {
      toast.success('Force upgrade values saved!')
    }
    bag.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        softUpgradeSemver: orgData?.forceUpgrade?.softUpgradeSemver || '',
        softUpgradeMessage: orgData?.forceUpgrade?.softUpgradeMessage || '',
        hardUpgradeSemver: orgData?.forceUpgrade?.hardUpgradeSemver || '',
        hardUpgradeMessage: orgData?.forceUpgrade?.hardUpgradeMessage || '',
      }}
      onSubmit={onSave}
      enableReinitialize={true}
      validationSchema={IOrgForceUpgradeValidationSchema}
    >
      {formikProps => {
        return (
          <Paper
            title="Force Upgrade"
            actions={
              <div className="flex flex-row align-center space-x-2">
                <Button
                  colorScheme="green"
                  type="submit"
                  onClick={() => formikProps.handleSubmit()}
                  disabled={!formikProps.dirty}
                  isLoading={formikProps.isSubmitting}
                >
                  Save
                </Button>
              </div>
            }
          >
            <SimpleGrid columns={2} spacing={8} p={3}>
              <Box>
                {/* Soft Upgrade */}
                <FormInput
                  label="Soft Upgrade Version (upgrade suggested if user <= this version)"
                  name="softUpgradeSemver"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.softUpgradeSemver}
                />
                <FormInput
                  label="Soft Upgrade Message"
                  name="softUpgradeMessage"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.softUpgradeMessage}
                  isTextArea={true}
                  inputProps={{ height: '100px' }}
                />
              </Box>
              <Box>
                {/* Hard Upgrade */}
                <FormInput
                  label="Hard Upgrade Version (upgrade required if user <= this version)"
                  name="hardUpgradeSemver"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.hardUpgradeSemver}
                />
                <FormInput
                  label="Hard Upgrade Message"
                  name="hardUpgradeMessage"
                  onChange={formikProps.handleChange}
                  value={formikProps.values.hardUpgradeMessage}
                  isTextArea={true}
                  inputProps={{ height: '100px' }}
                />
              </Box>
              <Box>
                {/* Upgrade Info */}
                <Heading size="sm" mb={2}>
                  Message Replacement Tokens
                </Heading>
                <UnorderedList>
                  {messageReplacementTokens.map(token => (
                    <ListItem
                      key={token.value}
                    >{`${token.value} ${token.description}`}</ListItem>
                  ))}
                </UnorderedList>
              </Box>
            </SimpleGrid>
          </Paper>
        )
      }}
    </Formik>
  )
}

/**
 * Form Validation
 * -----------------------------------------------------------------------------
 */

const messageReplacementTokens = [
  {
    value: '[USER_APP_SEMVER]',
    description: "The semantic version of the user's app",
  },
  {
    value: '[SOFT_UPGRADE_SEMVER]',
    description: 'The soft upgrade semantic version',
  },
  {
    value: '[HARD_UPGRADE_SEMVER]',
    description: 'The hard upgrade semantic version',
  },
]

/**
 * Find and return the first invalid token in the given string.
 * A token is defined as a string that starts with '[' and ends with ']'.
 * If the value is undefined or has no invalid tokens, return undefined.
 */
const findInvalidToken = (value?: string) => {
  if (!value) return undefined // no value, no problem
  const tokens = value.match(/(\[\w+\])/g)
  if (!tokens) return undefined // no tokens, no problem
  return tokens.find(token => {
    return !messageReplacementTokens.some(
      replacementToken => replacementToken.value === token,
    )
  })
}

/**
 * @return {boolean} true if the given value has no invalid tokens.
 */
const hasNoInvalidTokens = (value?: string) => {
  return !findInvalidToken(value)
}

/**
 * Assume there is an invalid token in the given value, and return an error
 * message string that includes the first invalid token found.
 */
const invalidTokenMessage = ({ value }) =>
  `Contains an invalid replacement token ${findInvalidToken(value)}.`

/**
 * Valid characters
 */
const validCharacters = '\\w\\s!@#$%^&()\\-=+{};:,.\\[\\]'
const invalidCharacterRegex = new RegExp('[^' + validCharacters + ']', 'g')

/**
 * @return {boolean} true if the given value has no invalid characters.
 */
const hasNoInvalidCharacters = (value?: string) => {
  if (!value) return true
  return !invalidCharacterRegex.test(value)
}

/**
 * Find and return the first invalid character in the given string.
 * If the value is undefined or has no invalid characters, return undefined.
 */
const findFirstInvalidCharacter = (value?: string) => {
  if (!value) return undefined
  const match = value.match(invalidCharacterRegex)
  return match && match.length > 0 ? match[0] : undefined
}

/**
 * Assume there is an invalid character in the given value, and return an error
 * message string that includes the first invalid character found.
 */
const invalidCharacterMessage = ({ value }) =>
  `Contains an invalid character ${findFirstInvalidCharacter(value)}.`

const notValidSemverMessage =
  'Must be a valid semantic version: <major>.<minor>.<patch>, e.g. 11.0.0'

/**
 * @return true if the `value` is less than or equal to the soft upgrade semver
 */
const lteSoftUpgradeSemver = (value, context) => {
  const { softUpgradeSemver } = context.parent
  if (!semver.valid(value) || !semver.valid(softUpgradeSemver)) {
    return false
  }
  return semver.lte(value, softUpgradeSemver)
}

/**
 * Yup validation schema for IOrgForceUpgrade
 */
const IOrgForceUpgradeValidationSchema = Yup.object().shape({
  softUpgradeSemver: Yup.string()
    .required('Required')
    .test('isValidSemver', notValidSemverMessage, semver.valid),
  softUpgradeMessage: Yup.string()
    .required('Required')
    .test('allValidTokens', invalidTokenMessage, hasNoInvalidTokens)
    .test('allValidChars', invalidCharacterMessage, hasNoInvalidCharacters),
  hardUpgradeSemver: Yup.string()
    .required('Required')
    .test('isValidSemver', notValidSemverMessage, semver.valid)
    .test(
      'lteSoftUpgradeSemver',
      'Must be less than or equal to Soft Upgrade Version',
      lteSoftUpgradeSemver,
    ),
  hardUpgradeMessage: Yup.string()
    .required('Required')
    .test('allValidTokens', invalidTokenMessage, hasNoInvalidTokens)
    .test('allValidChars', invalidCharacterMessage, hasNoInvalidCharacters),
})
