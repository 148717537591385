import React from 'react'
import * as R from 'ramda'
import { Box, Flex, FormLabel } from '@chakra-ui/react'
import { Formik } from 'formik'

import useDoc from 'lib/api/hooks/useDoc'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'
import IOrganization from 'domains/organization/models/IOrganization'
import ModalWrapper from 'lib/components/ModalWrapper'
import FormInput from 'lib/components/FormInput'
import { hasValue } from 'lib/utils'
import FileUploadInput from 'lib/components/FileUploadInput'

interface IProps {
  intro: string
  isOpen: boolean
  close: () => any
}

interface IFormValues {
  text: string
  video: string
}

export default function UpdateIntrosEditorModal(props: IProps) {
  const [orgData, , updateOrg] = useDoc(DEFAULT_ORG_PATH)
  const onSave = async (values: IFormValues) => {
    await updateOrg({
      pauseIntroText: R.assoc(props.intro, values.text, org.pauseIntroText),
      pauseIntroVideos: R.assoc(
        props.intro,
        values.video,
        org.pauseIntroVideos,
      ),
    })
    props.close()
  }

  if (!orgData) {
    return null
  }

  const org = orgData as IOrganization
  return (
    <Formik
      initialValues={{
        text: R.pathOr('', ['pauseIntroText', props.intro], org),
        video: R.pathOr('', ['pauseIntroVideos', props.intro], org),
      }}
      onSubmit={onSave}
      enableReinitialize={true}
    >
      {bag => {
        const onVideoUploaded = (video: string) =>
          bag.setFieldValue('video', video)
        return (
          <ModalWrapper
            title={`Update ${
              props.intro === '0' ? 'App Intro' : `${props.intro} minute`
            }`}
            isOpen={props.isOpen}
            pending={bag.isSubmitting}
            actions={[
              {
                text: 'Save',
                onClick: bag.handleSubmit,
                extra: { type: 'submit' },
              },
              {
                text: 'Cancel',
                colorScheme: 'gray',
                onClick: props.close,
                extra: { type: 'button' },
              },
            ]}
          >
            <Box p={3}>
              <FormLabel id="video" htmlFor="video">
                Video
              </FormLabel>
              {hasValue(bag.values.video) && (
                <video width="100%" controls={true}>
                  <source src={bag.values.video} />
                </video>
              )}
              <Flex direction="row">
                <FileUploadInput type="VIDEO" onUploaded={onVideoUploaded} />
              </Flex>

              <Box mt={3} />
              <FormInput
                label="Text"
                name="text"
                onChange={bag.handleChange}
                value={bag.values.text}
                isTextArea={true}
                inputProps={{ height: '400px' }}
              />
            </Box>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
