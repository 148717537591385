import { getPauseSegmentsPath } from 'domains/pauses/models/IPause'

export type ISegmentType =
  | 'IMAGE'
  | 'VIDEO'
  | 'MUSIC'
  | 'CAPTION'
  | 'VOICE'
  | 'BREATHE_START'
  | 'BREATHE_IN'
  | 'BREATHE_OUT'
  | 'ADD_NEW_MEDIA'

export interface ISegment {
  id: string
  name: string
  type: ISegmentType
  start: number
  end: number
  asset: any
  breathMessage?: string
  breatheLength?: number
  breatheColor?: string
  branch?: number
}

export const mockSegment = (overrides?: object) => ({
  id: '',
  name: '',
  type: 'CAPTION' as ISegmentType,
  start: 0,
  end: 0,
  asset: '',
  breathMessage: '',
  breatheLength: 0,
  breatheColor: '',
  ...(overrides || {}),
})

export const getSegmentPath = (pauseId: string, segmentId: string) =>
  `${getPauseSegmentsPath(pauseId)}/${segmentId}`

export const getIconForSegmentType = (type: ISegmentType): string => {
  switch (type) {
    case 'IMAGE':
      return 'image'
    case 'VIDEO':
      return 'videocam'
    case 'MUSIC':
      return 'music_note'
    case 'CAPTION':
      return 'closed_caption'
    default:
      return 'sentiment_satisfied_alt'
  }
}

export const isBreathSegment = (type: ISegmentType) => {
  switch (type) {
    case 'BREATHE_START':
    case 'BREATHE_IN':
    case 'BREATHE_OUT':
      return true
    default:
      return false
  }
}

export const isMediaSegment = (type: ISegmentType) => {
  switch (type) {
    case 'IMAGE':
    case 'VOICE':
    case 'MUSIC':
    case 'VIDEO':
      return true
    default:
      return false
  }
}

export const isTextSegment = (type: ISegmentType) => {
  switch (type) {
    case 'CAPTION':
      return true
    default:
      return false
  }
}
