import React, { useState } from 'react'
import * as R from 'ramda'
import { Box, Flex, Button } from '@chakra-ui/react'
import { orderBy, where } from 'firebase/firestore'

import { List } from 'lib/components'
import IPause, { getPausePath } from 'domains/pauses/models/IPause'
import SegmentEditorRow from 'domains/pauses/components/SegmentEditorRow'
import NewSegmentModal from 'domains/pauses/components/NewSegmentModal'
import { ISegment } from 'domains/segments/models/ISegment'
import useCollection from 'lib/api/hooks/useCollection'
import { addDoc } from 'lib/api/connection'
import memoize from 'fast-memoize'

interface IProps {
  pause: IPause
  branch?: number
}

const order = orderBy('start', 'asc')
const getQueries = memoize((branch?: number) => {
  if (branch) return [where('branch', '==', branch), order]

  return [order]
})

const preBranchSegmentsOnly = (segment: ISegment) => !segment.branch

export default function PauseSegments(props: IProps) {
  const [addOpen, setAddOpen] = useState(false)
  const segmentsPath = `${getPausePath(props.pause.id)}/segments`
  const queries = getQueries(props.branch)
  const [segmentsData] = useCollection(segmentsPath, queries)
  const segments = segmentsData as ISegment[]

  const onAddSegment = () => setAddOpen(true)
  const onCloseAddSegment = () => setAddOpen(false)
  const onNewSegment = async (segment: ISegment) => {
    let newSegment = R.has('start', segment)
      ? segment
      : {
          ...R.pick(['asset', 'name', 'type'], segment),
          start: R.last(segments)
            ? Math.min(
                props.pause.length,
                (R.last(segments) as ISegment).end + 1000,
              )
            : 0,
          end: props.pause.length,
        }
    if (props.branch) {
      newSegment = R.assoc('branch', props.branch, newSegment)
    }
    await addDoc(segmentsPath, newSegment)
    onCloseAddSegment()
  }

  return (
    <Box>
      <List
        collection={segmentsPath}
        queries={queries}
        filter={props.branch === 0 ? preBranchSegmentsOnly : undefined}
        RowComponent={SegmentEditorRow}
        rowProps={{ pause: props.pause }}
      />
      <Flex direction="column" align="center" justify="center" mt={2}>
        <Button onClick={onAddSegment} colorScheme="blue">
          Add Segment
        </Button>
      </Flex>

      <NewSegmentModal
        isOpen={addOpen}
        close={onCloseAddSegment}
        onNewSegment={onNewSegment}
        pause={props.pause}
      />
    </Box>
  )
}
