import React from 'react'
import * as R from 'ramda'
import { Formik, FormikHelpers } from 'formik'
import {
  Box,
  Spinner,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react'
import { toast } from 'react-toastify'

import ModalWrapper from 'lib/components/ModalWrapper'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'
import useDoc from 'lib/api/hooks/useDoc'
import IOrganization, {
  IPauseUnlockingRequirements,
} from 'domains/organization/models/IOrganization'
import { convertToInt } from 'lib/utils'

interface IProps {
  isOpen: boolean
  close: () => any
}

export default function OrgUnlockingEditorModal(props: IProps) {
  const [orgData, , updateOrg] = useDoc(DEFAULT_ORG_PATH)
  const onSave = async (
    pauseUnlockingRequirements: IPauseUnlockingRequirements,
    bag: FormikHelpers<IPauseUnlockingRequirements>,
  ) => {
    if (R.any(R.equals(''))(R.values(pauseUnlockingRequirements) as any)) {
      bag.setSubmitting(false)
      toast.error('Invalid unlocking count')

      return
    }
    await updateOrg({ pauseUnlockingRequirements })
    props.close()
  }

  if (!orgData) {
    return (
      <ModalWrapper
        title="Pause Unlocking Requirements"
        isOpen={props.isOpen}
        pending={true}
        actions={[]}
      >
        <Spinner />
      </ModalWrapper>
    )
  }

  const org = orgData as IOrganization
  return (
    <Formik initialValues={org.pauseUnlockingRequirements} onSubmit={onSave}>
      {bag => {
        const onChange = (type: string) => (value: any) =>
          bag.setFieldValue(type, convertToInt(value, ''))

        return (
          <ModalWrapper
            title="Pause Unlocking Requirements"
            isOpen={props.isOpen}
            pending={bag.isSubmitting}
            actions={[
              {
                text: 'Save',
                onClick: bag.handleSubmit,
                extra: { type: 'submit' },
              },
              {
                text: 'Cancel',
                colorScheme: 'gray',
                onClick: props.close,
                extra: { type: 'button' },
              },
            ]}
          >
            {(R.keys(bag.values) as string[]).map(type => (
              <Box key={type} pt={3}>
                <Text fontSize="lg">{type} minute</Text>
                <NumberInput
                  min={1}
                  key={type}
                  isInvalid={false}
                  value={bag.values[type]}
                  onChange={onChange(type)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>
            ))}
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
