import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Text, Checkbox, Spinner } from '@chakra-ui/react'
import ModalWrapper from 'lib/components/ModalWrapper'
import IOrganization, {
  IOrgHeartCheck,
} from 'domains/organization/models/IOrganization'
import colors from 'lib/styles/colors'
import { ObjectArrayInput } from './ObjectArrayInput'

const validationSchema = Yup.object().shape({
  enabled: Yup.boolean().required(),
  moodScaleLabels: Yup.array(
    Yup.object().shape({
      label: Yup.string().required(),
    }),
  ),
  moodDescriptors: Yup.array(
    Yup.object().shape({
      label: Yup.string().required(),
      range: Yup.object().shape({
        min: Yup.number()
          .min(-1)
          .max(1)
          .required(),
        max: Yup.number()
          .min(-1)
          .max(1)
          .required(),
      }),
    }),
  ),
  moodImpactors: Yup.array(
    Yup.object().shape({
      label: Yup.string().required(),
    }),
  ),
})

interface IProps {
  org: IOrganization
  updateOrg: (d: any) => Promise<IOrganization>
  close: () => any
}

export default function OnboardingEditorModal(props: IProps) {
  const modalTitle = 'Heart Check'

  const onSubmit = async (
    heartCheck: IOrgHeartCheck,
    bag: FormikHelpers<IOrgHeartCheck>,
  ) => {
    await props.updateOrg({ heartCheck })
    bag.setSubmitting(false)
    props.close()
  }

  if (!props.org.heartCheck) {
    return (
      <ModalWrapper
        title={modalTitle}
        isOpen={true}
        pending={true}
        actions={[]}
      >
        <Spinner />
      </ModalWrapper>
    )
  }
  return (
    <Formik
      initialValues={props.org.heartCheck}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {bag => (
        <ModalWrapper
          title={modalTitle}
          isOpen={true}
          pending={bag.isSubmitting}
          actions={[
            {
              text: 'Save',
              onClick: bag.handleSubmit,
              extra: { type: 'submit', disabled: !bag.isValid },
            },
            {
              text: 'Cancel',
              colorScheme: 'gray',
              onClick: props.close,
              extra: { type: 'button', isLoading: false },
            },
          ]}
        >
          {/* Enabled Flag */}
          <Checkbox
            isChecked={bag.values.enabled}
            onChange={bag.handleChange}
            name="enabled"
          >
            Enabled
          </Checkbox>
          <FieldError error={bag.errors.enabled} />

          {/* Mood Scale Labels */}
          <Text mt={4} mb={2}>
            Mood Scale Labels (Ordered)
          </Text>
          <ObjectArrayInput
            defaultValue={JSON.stringify(props.org.heartCheck?.moodScaleLabels)}
            value={bag.values.moodScaleLabels}
            onChange={e => setBagValueFromOnChange(bag, 'moodScaleLabels', e)}
          />
          <FieldError error={bag.errors.moodScaleLabels} />

          {/* Mood Descriptors */}
          <Text mt={4} mb={2}>
            Mood Descriptors (Unordered)
          </Text>
          <ObjectArrayInput
            defaultValue={JSON.stringify(props.org.heartCheck?.moodDescriptors)}
            value={bag.values.moodDescriptors}
            onChange={e => setBagValueFromOnChange(bag, 'moodDescriptors', e)}
          />
          <FieldError error={bag.errors.moodDescriptors} />

          {/* Mood Impactors */}
          <Text mt={4} mb={2}>
            Mood Impactors (Unordered)
          </Text>
          <ObjectArrayInput
            defaultValue={JSON.stringify(props.org.heartCheck?.moodImpactors)}
            value={bag.values.moodImpactors}
            onChange={e => setBagValueFromOnChange(bag, 'moodImpactors', e)}
          />
          <FieldError error={bag.errors.moodImpactors} />
        </ModalWrapper>
      )}
    </Formik>
  )
}

const setBagValueFromOnChange = (bag: any, key: string, event: any) => {
  try {
    const parsed = JSON.parse(event.target.value)
    bag.setFieldValue(key, parsed)
  } catch (error) {
    bag.setFieldError(key, `${key}: Invalid JSON`)
  }
}

const FieldError = (props: { error: any }) => {
  if (!props.error) {
    return null
  }
  return (
    <Text color={colors.error} mt={2}>
      {typeof props.error === 'string'
        ? props.error
        : JSON.stringify(props.error)}
    </Text>
  )
}
