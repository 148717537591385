import React from 'react'
import styled from 'styled-components'
import { Box, Button } from '@chakra-ui/react'

interface Props {
  text: string
  name: string
  onChange: (event: React.FormEvent<HTMLInputElement>) => void
  'aria-label'?: string
  accept?: string
}

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity={0}
  overflow: hidden;
  position: absolute;
  zIndex: -1;
`

const FileInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const input = React.useRef<HTMLInputElement>(null)
  const onBrowse = () => {
    if (input.current) input.current.click()
    // @ts-ignore
    else if (ref && ref.current) ref.current.click()
  }

  return (
    <Box>
      <Input
        ref={ref || input}
        type="file"
        name={props.name}
        onChange={props.onChange}
        accept={props.accept}
        aria-label={props['aria-label']}
      />

      <Button
        colorScheme="blue"
        onClick={onBrowse}
        type="button"
        aria-label={props['aria-label']}
      >
        {props.text}
      </Button>
    </Box>
  )
})

export default FileInput
