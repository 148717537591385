import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Image,
  Select,
  Spinner,
} from '@chakra-ui/react'
import uuid from 'uuid/v4'

import ModalWrapper from 'lib/components/ModalWrapper'
import FormInput from 'lib/components/FormInput'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'
import {
  IOrgAnnouncement,
  AnnouncementType,
} from 'domains/organization/models/IOrganization'
import useAnnouncement from 'domains/organization/hooks/useAnnouncement'
import { hasValue } from 'lib/utils'
import FileUploadInput from 'lib/components/FileUploadInput'
import { updateDoc } from 'lib/api/connection'

const validationSchema = Yup.object().shape({
  visible: Yup.boolean().required(),
  type: Yup.mixed()
    .oneOf<AnnouncementType>(['HomeBottomSheet', 'FullScreenVideo'])
    .required(),
  bannerTitle: Yup.string().required(),
  bannerSubtitle: Yup.string(),
  title: Yup.string().required(),
  titleImage: Yup.string(),
  video: Yup.string(),
  subtitle: Yup.string(),
  body: Yup.string(),
  showBuyBook: Yup.boolean().required(),
  shareLink: Yup.string(),
  ctaLabel: Yup.string().nullable(),
  ctaLink: Yup.string()
    .url()
    .nullable(),
  ctaIconName: Yup.string().nullable(),
  ctaIconLibrary: Yup.mixed()
    .oneOf([
      'PauseIcons',
      'AntDesign',
      'Entypo',
      'Ionicons',
      'Material',
      'MaterialCommunity',
    ])
    .nullable(),
})

interface IProps {
  close: () => any
}

export default function AnnouncementEditorModal(props: IProps) {
  const [announcementData, pending] = useAnnouncement()

  const onSave = async (
    newAnnouncement: IOrgAnnouncement,
    bag: FormikHelpers<IOrgAnnouncement>,
  ) => {
    await updateDoc(DEFAULT_ORG_PATH, {
      announcement: {
        ...newAnnouncement,
        version: uuid(),
      } as IOrgAnnouncement,
    })
    bag.setSubmitting(false)
    props.close()
  }

  if (pending) {
    return (
      <ModalWrapper
        title="Announcement"
        isOpen={true}
        pending={true}
        actions={[]}
      >
        <Spinner />
      </ModalWrapper>
    )
  }

  const announcement = announcementData as IOrgAnnouncement

  return (
    <Formik
      initialValues={announcement}
      validationSchema={validationSchema}
      onSubmit={onSave}
    >
      {bag => {
        const onImageUploaded = (image: string) =>
          bag.setFieldValue('titleImage', image)
        const onRemoveImage = () => bag.setFieldValue('titleImage', '')

        const onVideoUploaded = (video: string) =>
          bag.setFieldValue('video', video)
        const onRemoveVideo = () => bag.setFieldValue('video', '')

        const onVideoThumbnailUploaded = (video: string) =>
          bag.setFieldValue('videoThumbnail', video)
        const onRemoveThumbnailVideo = () =>
          bag.setFieldValue('videoThumbnail', '')

        return (
          <ModalWrapper
            title="Announcement"
            isOpen={true}
            pending={bag.isSubmitting}
            actions={[
              {
                text: 'Save',
                onClick: bag.handleSubmit,
                extra: { type: 'submit' },
              },
              {
                text: 'Cancel',
                colorScheme: 'gray',
                onClick: props.close,
                extra: { type: 'button' },
              },
            ]}
          >
            <Box>
              <Box>
                <Checkbox
                  isChecked={bag.values.visible}
                  onChange={bag.handleChange}
                  name="visible"
                >
                  Visible to all users
                </Checkbox>
              </Box>

              <Box>
                <Checkbox
                  isChecked={bag.values.adminVisible}
                  onChange={bag.handleChange}
                  name="adminVisible"
                >
                  Visible to admins
                </Checkbox>
              </Box>

              <Box>
                <Checkbox
                  isChecked={!!bag.values.showBadge}
                  onChange={bag.handleChange}
                  name="showBadge"
                >
                  Show badge
                </Checkbox>
              </Box>

              <Heading as="h1" size="md" mt="5" noOfLines={1}>
                Type
              </Heading>
              <Divider />
              <Select
                name="type"
                onChange={bag.handleChange}
                value={bag.values.type}
                mt="5"
              >
                <option value="HomeBottomSheet">Home Bottom Sheet</option>
                <option value="FullScreenVideo">Full Screen Video</option>
              </Select>

              {/* Home Bottom Sheet Type Settings */}
              {bag.values.type === 'HomeBottomSheet' && (
                <Box>
                  <Box mt="5">
                    <Checkbox
                      isChecked={bag.values.showBuyBook}
                      onChange={bag.handleChange}
                      name="showBuyBook"
                    >
                      Show buy book button
                    </Checkbox>
                  </Box>

                  <FormInput
                    label="Banner Title"
                    name="bannerTitle"
                    onChange={bag.handleChange}
                    value={bag.values.bannerTitle}
                  />
                  <FormInput
                    label="Banner Subtitle"
                    name="bannerSubtitle"
                    onChange={bag.handleChange}
                    value={bag.values.bannerSubtitle}
                  />
                  <FormInput
                    label="Beta Banner Title"
                    name="betaBannerTitle"
                    onChange={bag.handleChange}
                    value={bag.values.betaBannerTitle}
                  />
                  <FormInput
                    label="Beta Banner Subtitle"
                    name="betaBannerSubtitle"
                    onChange={bag.handleChange}
                    value={bag.values.betaBannerSubtitle}
                  />
                  <FormInput
                    label="Page Title"
                    name="title"
                    onChange={bag.handleChange}
                    value={bag.values.title}
                  />
                  <FormInput
                    label="Page Subtitle"
                    name="subtitle"
                    onChange={bag.handleChange}
                    value={bag.values.subtitle}
                  />
                  <FormInput
                    label="Share Link"
                    name="shareLink"
                    onChange={bag.handleChange}
                    value={bag.values.shareLink}
                  />

                  <Box mt={3} />
                  <FormLabel id="titleImage" htmlFor="titleImage">
                    Page Image
                  </FormLabel>
                  <Flex direction="row">
                    {hasValue(bag.values.titleImage) && (
                      <Button colorScheme="red" onClick={onRemoveImage} mr={2}>
                        Remove
                      </Button>
                    )}
                    <FileUploadInput
                      type="IMAGE"
                      onUploaded={onImageUploaded}
                    />
                  </Flex>

                  <Box mt={3} />
                  <FormLabel id="videoThumbnail" htmlFor="videoThumbnail">
                    Video Thumbnail
                  </FormLabel>
                  {hasValue(bag.values.videoThumbnail) && (
                    <Image
                      src={bag.values.videoThumbnail}
                      style={{ maxWidth: '100%', maxHeight: 200 }}
                    />
                  )}
                  <Flex direction="row">
                    {hasValue(bag.values.videoThumbnail) && (
                      <Button
                        colorScheme="red"
                        onClick={onRemoveThumbnailVideo}
                        mr={2}
                      >
                        Remove
                      </Button>
                    )}
                    <FileUploadInput
                      type="IMAGE"
                      onUploaded={onVideoThumbnailUploaded}
                    />
                  </Flex>

                  <Box mt={3} />
                  <FormLabel id="video" htmlFor="video">
                    Page Video
                  </FormLabel>
                  {hasValue(bag.values.video) && (
                    <video width="100%" controls={true}>
                      <source src={bag.values.video} />
                    </video>
                  )}
                  <Flex direction="row">
                    {hasValue(bag.values.video) && (
                      <Button colorScheme="red" onClick={onRemoveVideo} mr={2}>
                        Remove
                      </Button>
                    )}
                    <FileUploadInput
                      type="VIDEO"
                      onUploaded={onVideoUploaded}
                    />
                  </Flex>

                  <FormInput
                    label="Page Body"
                    name="body"
                    onChange={bag.handleChange}
                    value={bag.values.body}
                    isTextArea={true}
                    inputProps={{ height: '400px' }}
                  />

                  <FormInput
                    label="CTA Button Label"
                    name="ctaLabel"
                    onChange={bag.handleChange}
                    value={bag.values.ctaLabel}
                  />

                  <Box mt={3} />
                  <FormInput
                    label="CTA Button Link"
                    name="ctaLink"
                    onChange={bag.handleChange}
                    value={bag.values.ctaLink}
                  />

                  <Box mt={3} />
                  <Flex direction="row" alignItems="center">
                    <Box>
                      <FormLabel id="ctaIconLibrary" htmlFor="ctaIconLibrary">
                        CTA Icon Library
                      </FormLabel>
                      <select
                        name="ctaIconLibrary"
                        value={bag.values.ctaIconLibrary}
                        onChange={bag.handleChange}
                      >
                        <option value="">Select One</option>
                        <option value="PauseIcons">PauseIcons</option>
                        <option value="AntDesign">AntDesign</option>
                        <option value="Entypo">Entypo</option>
                        <option value="Ionicons">Ionicons</option>
                        <option value="Material">Material</option>
                        <option value="MaterialCommunity">
                          MaterialCommunity
                        </option>
                      </select>
                    </Box>

                    <FormInput
                      label="CTA Icon Name"
                      name="ctaIconName"
                      onChange={bag.handleChange}
                      value={bag.values.ctaIconName}
                    />
                  </Flex>
                </Box>
              )}

              {/* Full Screen Video Type Settings */}
              {bag.values.type === 'FullScreenVideo' && (
                <Box>
                  <FormLabel mt={3} id="video" htmlFor="video">
                    Video
                  </FormLabel>
                  {hasValue(bag.values.video) && (
                    <video width="100%" controls={true}>
                      <source src={bag.values.video} />
                    </video>
                  )}
                  <Flex direction="row">
                    {hasValue(bag.values.video) && (
                      <Button colorScheme="red" onClick={onRemoveVideo} mr={2}>
                        Remove
                      </Button>
                    )}
                    <FileUploadInput
                      type="VIDEO"
                      onUploaded={onVideoUploaded}
                    />
                  </Flex>

                  <FormInput
                    label="Banner Title"
                    name="bannerTitle"
                    onChange={bag.handleChange}
                    value={bag.values.bannerTitle}
                  />
                  <FormInput
                    label="Banner Subtitle"
                    name="bannerSubtitle"
                    onChange={bag.handleChange}
                    value={bag.values.bannerSubtitle}
                  />

                  <FormInput
                    label="Page Title"
                    name="title"
                    onChange={bag.handleChange}
                    value={bag.values.title}
                  />

                  <FormInput
                    label="CTA Button Label"
                    name="ctaLabel"
                    onChange={bag.handleChange}
                    value={bag.values.ctaLabel}
                  />

                  <Box mt={3} />
                  <FormInput
                    label="CTA Button Link"
                    name="ctaLink"
                    onChange={bag.handleChange}
                    value={bag.values.ctaLink}
                  />
                </Box>
              )}
            </Box>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
