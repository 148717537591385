import React, { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { Flex, Box, Text, Icon } from '@chakra-ui/react'

import IPause from 'domains/pauses/models/IPause'
import { milliSecondsToFriendlyTime } from 'lib/utils/time'
import { dotJoinItems } from 'lib/utils/strings'

interface IProps extends IPause {
  onDuplicate: (pause: IPause) => any
  onDelete: (pause: IPause) => any
}

export default function PauseRow(props: IProps) {
  const onDuplicate = async (e: MouseEvent) => {
    e.preventDefault()
    await props.onDuplicate(props)
  }

  const onDelete = async (e: MouseEvent) => {
    e.preventDefault()
    await props.onDelete(props)
  }

  return (
    <Link to={`/pauses/${props.id}`}>
      <Flex
        p={3}
        bg="white"
        direction="row"
        justify="space-between"
        align="center"
        cursor="pointer"
      >
        <Box>
          <Text fontSize="lg">{props.title}</Text>
          <Flex direction="row" align="center">
            <Text>
              {dotJoinItems([
                milliSecondsToFriendlyTime(props.length),
                props.published ? 'Published' : 'Unpublished',
              ])}
            </Text>
          </Flex>
        </Box>

        <Flex direction="row" align="center">
          <Box onClick={onDuplicate} cursor="pointer" mr={2}>
            <Icon name="copy" />
          </Box>
          <Box onClick={onDelete} cursor="pointer" mr={2}>
            <Icon name="delete" />
          </Box>
        </Flex>
      </Flex>
    </Link>
  )
}
