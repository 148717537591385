import React from 'react'
import { Flex, Button, Spinner, Text, Box } from '@chakra-ui/react'
import Paper from 'lib/components/Paper'
import useDoc from 'lib/api/hooks/useDoc'
import { DEFAULT_ORG_PATH } from 'lib/api/apiConstants'
import IOrganization from 'domains/organization/models/IOrganization'
import useModalState from 'lib/api/hooks/useModalState'
import HeartCheckEditorModal from 'domains/organization/components/HeartCheckEditorModal'

export default function Onboarding() {
  const [org, orgIsLoading, updateOrg] = useDoc<IOrganization>(DEFAULT_ORG_PATH)
  const [editOpen, openEditModal, closeEditModal] = useModalState()
  const sectionTitle = 'Heart Check'

  if (!org || orgIsLoading) {
    return (
      <Paper title={sectionTitle}>
        <Spinner />
      </Paper>
    )
  }
  return (
    <Paper
      title={sectionTitle}
      actions={
        <Flex direction="row" align="center">
          <Button onClick={openEditModal} colorScheme="green" mr={2}>
            Edit
          </Button>
        </Flex>
      }
    >
      <Box p={3}>
        <Text>
          {`${sectionTitle} Enabled: `}
          <span style={{ fontWeight: 700 }}>
            {org.heartCheck?.enabled ? 'TRUE' : 'FALSE'}
          </span>
        </Text>
      </Box>

      {editOpen && (
        <HeartCheckEditorModal
          org={org}
          updateOrg={updateOrg}
          close={closeEditModal}
        />
      )}
    </Paper>
  )
}
