import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Divider,
  Grid,
  Input,
  Skeleton,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react'
import { Text } from '@chakra-ui/core'
import { RouteComponentProps } from 'react-router'
import Paper from 'lib/components/Paper'
import debounce from 'lodash.debounce'
import { DEFAULT_USERS_PATH } from 'lib/api/apiConstants'
import { where } from 'firebase/firestore'
import { getCollectionGroupCount, getCount } from 'lib/api/connection'
import {
  InstantSearch,
  useHits,
  useSearchBox,
} from 'react-instantsearch-hooks-web'

import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'

const searchClient = instantMeiliSearch(
  'https://search.pauseapp.com',
  '701357b82ccf870cf91bba8329b2f1b8e2ea584d1dabead0ccc0f9796168c86c',
)

export default function UsersScreen(props: RouteComponentProps) {
  const [anonUsersCount, setAnonUsersCount] = useState<number>(0)
  const [emailUsersCount, setEmailUsersCount] = useState<number>(0)
  const [totalUserCount, setTotalUserCount] = useState<number>(0)
  const [resilientEnrollmentsCount, setResilientEnrollmentsCount] = useState<
    number
  >(0)
  const [resilientCompletionCount, setResilientCompletionCount] = useState<
    number
  >(0)

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  async function loadData() {
    await Promise.all([
      getCount(DEFAULT_USERS_PATH).then(count => setTotalUserCount(count)),
      getCount(DEFAULT_USERS_PATH, [
        where('loginMethod', '==', 'ANONYMOUS'),
      ]).then(count => setAnonUsersCount(count)),
      getCount(DEFAULT_USERS_PATH, [
        where('loginMethod', '==', 'EMAIL'),
      ]).then(count => setEmailUsersCount(count)),
      getCount(DEFAULT_USERS_PATH, [
        where('programSubscriptions.BJNwAkfJJu0AMYgCfdtB', '>', ''),
      ]).then(count => setResilientEnrollmentsCount(count)),
      getCollectionGroupCount('program_subscriptions', [
        where('completed', '==', true),
      ]).then(count => setResilientCompletionCount(count)),
    ])
    setIsLoaded(true)
  }
  useEffect(() => {
    loadData()
  }, [])

  return (
    <InstantSearch indexName="users" searchClient={searchClient}>
      <Box p={3}>
        <Grid templateColumns="repeat(5, 1fr)" gap={6}>
          {!isLoaded ? (
            <>
              <div>
                <Skeleton height="20px" my="10px" />
                <Skeleton height="20px" my="10px" />
                <Skeleton height="20px" my="10px" />
              </div>
              <div>
                <Skeleton height="20px" my="10px" />
                <Skeleton height="20px" my="10px" />
                <Skeleton height="20px" my="10px" />
              </div>
            </>
          ) : (
            <>
              <Stat>
                <StatLabel>Total Users</StatLabel>
                <StatNumber>{totalUserCount.toLocaleString()}</StatNumber>
                {anonUsersCount && emailUsersCount && (
                  <StatHelpText>
                    {anonUsersCount.toLocaleString()} Guest Accounts –{' '}
                    {emailUsersCount.toLocaleString()} Email
                  </StatHelpText>
                )}
              </Stat>
              <Stat>
                <StatLabel>Total Resilient Signups</StatLabel>
                <StatNumber>
                  {resilientEnrollmentsCount.toLocaleString()}
                </StatNumber>
                {resilientCompletionCount && (
                  <StatHelpText>
                    {resilientCompletionCount.toLocaleString()} completions
                  </StatHelpText>
                )}
              </Stat>
            </>
          )}
        </Grid>
      </Box>
      <Box p={3}>
        <Paper title="Users" actions={<SearchUsers {...props} />}>
          <Grid templateColumns="repeat(4, 1fr)" gap={6}>
            <Text fontWeight="bold">Email</Text>
            <Text fontWeight="bold">Name</Text>
            <Text fontWeight="bold">Login Method</Text>
          </Grid>
          <Divider />
          <Hits />
        </Paper>
      </Box>
    </InstantSearch>
  )
}

const Hits = props => {
  const { hits } = useHits<any>(props)

  return (
    <>
      {hits.map(hit => (
        <Grid mt={4} templateColumns="repeat(4, 1fr)" gap={6} key={hit.id}>
          <Box>
            <a href={`/users/${hit.id}`}>
              <strong>{hit.email}</strong>
            </a>
            <Text fontSize="xs">{hit.id}</Text>
          </Box>
          <Box>
            {hit.firstName} {hit.lastName}
          </Box>
          <Box>{hit.loginMethod}</Box>
          <a href={`/users/${hit.id}`}>View user</a>
        </Grid>
      ))}
    </>
  )
}

const SearchUsers = props => {
  const { query, refine, clear } = useSearchBox(props)
  const [userQuery, setUserQuery] = useState(query)

  const debounceFunc = debounce(() => {
    refine(userQuery)
  }, 500)

  const callbackFunc = useCallback(debounceFunc, [debounceFunc])

  const handleChange = event => {
    setUserQuery(event.target.value)
    if (event.target.value === '') {
      clear()
    } else {
      callbackFunc()
    }
  }

  return (
    <Input
      placeholder="Search Users"
      value={userQuery}
      onChange={handleChange}
      onKeyUp={handleChange}
    />
  )
}
