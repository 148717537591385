import { initializeApp } from 'firebase/app'
import {
  doc,
  getFirestore,
  getDoc as getFSDoc,
  updateDoc as updateFSDoc,
  addDoc as addFSDoc,
  deleteDoc as deleteFSDoc,
  QueryConstraint,
  CollectionReference,
  Query,
  collection,
  query,
  getDocs,
  onSnapshot,
  QuerySnapshot,
  getCountFromServer,
  collectionGroup,
} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { ISegmentType } from 'domains/segments/models/ISegment'
import { ORG_ID } from 'lib/api/apiConstants'
import { roundToDecimal } from 'lib/utils'

const firebaseConfig = {
  apiKey: 'AIzaSyAopMJ6NNT_dkfKLwar1Q2SBaPrq6lBaoc',
  authDomain: 'pause-247817.firebaseapp.com',
  databaseURL: 'https://pause-247817.firebaseio.com',
  projectId: 'pause-247817',
  storageBucket: 'pause-247817.appspot.com',
  messagingSenderId: '1001799063425',
  appId: '1:1001799063425:web:bec16a95ebd7a5e8',
}

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app)
export const functions = getFunctions(app)

/** Set boolean to true for local emulator, false to use cloud */
const USE_LOCAL_FUNCTIONS_EMULATOR = false
if (USE_LOCAL_FUNCTIONS_EMULATOR) {
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

export async function getDoc<T>(path: string): Promise<T> {
  const ref = (await getFSDoc(doc(db, path))) as any
  return { ...ref.data(), id: ref.id } as T
}

const getSnapshotListData = <T>(snapshot: QuerySnapshot): T[] => {
  const items: T[] = []
  if (snapshot) {
    snapshot.forEach(snap => {
      if (snap) {
        items.push({
          id: snap.id,
          ...snap.data(),
        } as any)
      }
    })
  }
  return items as T[]
}

export async function getCollectionGroupCount(
  path,
  queries: QueryConstraint[] = [],
): Promise<number> {
  const q = query(collectionGroup(db, path), ...queries)
  const snapshot = await getCountFromServer(q)
  return snapshot.data().count
}

export async function getCount(
  path,
  queries: QueryConstraint[] = [],
): Promise<number> {
  const coll = collection(db, path || 'users')

  let snapshot
  if (queries.length) {
    snapshot = await getCountFromServer(query(coll, ...queries))
  } else {
    snapshot = await getCountFromServer(coll)
  }
  return snapshot.data().count
}
export async function getCollection<T>(
  path: string,
  queries?: QueryConstraint[],
): Promise<T[]> {
  let ref: CollectionReference | Query = collection(db, path)
  if (queries) ref = query(ref, ...queries)

  const snaps = await getDocs(ref)
  return getSnapshotListData<T>(snaps)
}

export const listenToCollection = <T>(
  path: string,
  callback: (value: any) => any,
  queries?: QueryConstraint[],
) => {
  let ref: CollectionReference | Query = collection(db, path)
  if (queries) ref = query(ref, ...queries)

  return onSnapshot(ref, async snap => {
    if (!snap) {
      callback(null)
    }

    const collection = getSnapshotListData<T>(snap)
    callback(collection)
  })
}

export async function addDoc<T>(pathToCollection: string, value: any) {
  const docRef = await addFSDoc(collection(db, pathToCollection), value)
  return await getDoc<T>(`${pathToCollection}/${docRef.id}`)
}

export async function updateDoc<T>(path: string, value: any): Promise<T> {
  await updateFSDoc(doc(db, path), value)
  return await getDoc<T>(path)
}

export async function deleteDoc(path: string) {
  await deleteFSDoc(doc(db, path))
}

export interface ITimeStamp {
  seconds: number
  nanoseconds: number
}

export const getSegmentStorageDir = (type: ISegmentType) =>
  `organizations/${ORG_ID}/${type.toLowerCase()}s`

export const getV2PauseStorageDir = (pauseId: string) =>
  `organizations/${ORG_ID}/v2PauseAssets/${pauseId}`

export const uploadFile = (
  fullPath: string,
  file: any,
  onProgress?: (progress: number) => any,
): Promise<string | false> =>
  new Promise(res => {
    const fileRef = ref(storage, fullPath)
    const uploadTask = uploadBytesResumable(fileRef, file)
    uploadTask.on(
      'state_changed',
      snapshot => {
        if (onProgress)
          onProgress(
            roundToDecimal(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            ),
          )
      },
      error => {
        window.alert('Failed to upload file. Check console for errors.')
        console.error(error) // eslint-disable-line
        res(false)
      },
      async () => {
        const asset = await getDownloadURL(uploadTask.snapshot.ref)
        res(asset)
      },
    )
  })

export default app
