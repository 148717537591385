/* -----------------------------------------------------------------------------
 * Imports
 * ---------------------------------------------------------------------------*/
import React, { useCallback } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Progress,
} from '@chakra-ui/react'
import { orderBy } from 'firebase/firestore'
import { getCollection, getDoc } from 'lib/api/connection'
import IProgram, { IProgramDay, IProgramDayActivity } from '../models/IProgram'
import IPause, {
  getPauseTranscriptsPath,
  IPauseTranscript,
} from '../models/IPause'
import { getPausePath } from 'domains/pauses/models/IPause'
import {
  convertTranscriptsToSrtFileFormat,
  convertTranscriptsToTxtFileFormat,
} from 'lib/utils/convert'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

/* -----------------------------------------------------------------------------
 * Props
 * ---------------------------------------------------------------------------*/

interface IProps {
  /** If true, then display the Modal and initiate export. */
  isOpen: boolean

  /** Program data */
  program: IProgram

  /** Program days data */
  days: IProgramDay[]

  /** Close handler function */
  close: () => any
}

/* -----------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------*/

/**
 * Modal to wrap "export transcripts" functionality for a Program and to display
 * progress feedback to the user.
 */
export default function ExportProgramTranscriptsModal(props: IProps) {
  const { program, days, close } = props

  /* ---------------------------------------------------------------------------
   * Data / State
   * -------------------------------------------------------------------------*/

  /** Boolean to keep track of whether this component is mounted */
  const isMounted = React.useRef<boolean>(false)
  /** Export progress percentage, a value from 0.0 to 1.0 */
  const [exportPercent, setExportPercent] = React.useState<number>(0.0)
  /** Boolean to keep track of whether we are currently exporting or not */
  const isExportingRef = React.useRef<boolean>(false)
  /** Export status comment */
  const [statusText, setStatusText] = React.useState<string>('ready')

  /* ---------------------------------------------------------------------------
   * Functions
   * -------------------------------------------------------------------------*/

  /**
   * Export Program transcripts, an async function to fetch transcript data,
   * compile a zip file, and download it
   */
  const exportTranscripts = useCallback(async () => {
    // init
    const pauseActivities: IProgramDayActivity[] = getPauseActivities(days)
    const progressStepsPerPause = 3
    const totalProgressSteps = pauseActivities.length * progressStepsPerPause
    setExportPercent(0.0)
    const zip = new JSZip()

    // create "all transcripts" string
    let allTranscriptsText = ''
    const allTranscriptsDelimiter =
      '\n--------------------------------------------------------------------------------\n\n'

    // iterate through all Pauses (need index here to calculate progress)
    for (let index = 0; index < pauseActivities.length; index++) {
      // async function exit:
      // stop exporting if user cancels or if component is unmounted
      if (!isExportingRef.current || !isMounted.current) {
        return
      }

      // init
      const pauseActivity = pauseActivities[index] // current pause activity
      const previousProgressSteps = index * progressStepsPerPause
      setStatusText(`Processing Pause: ${pauseActivity.title}`)

      // load Pause data
      const pauseId = pauseActivity.refId
      const pausePath = getPausePath(pauseId, true)
      const pause = await getDoc<IPause>(pausePath)
      setExportPercent((previousProgressSteps + 1) / totalProgressSteps)

      // load Pause transcript data
      const transcriptsPath = getPauseTranscriptsPath(pauseId)
      const transcripts = await getCollection<IPauseTranscript>(
        transcriptsPath,
        [orderBy('start', 'asc')],
      )
      setExportPercent((previousProgressSteps + 2) / totalProgressSteps)

      // generate .srt and .txt files and add them to the .zip
      const filename = `${program.title}, ${pause.title}`
      const srtFileContents = convertTranscriptsToSrtFileFormat(transcripts)
      zip.file(`${filename}.srt`, srtFileContents)
      const txtFileContents = convertTranscriptsToTxtFileFormat(transcripts)
      zip.file(`${filename}.txt`, txtFileContents)
      setExportPercent((previousProgressSteps + 3) / totalProgressSteps)

      // build "all transcripts" string
      allTranscriptsText +=
        txtFileContents +
        (index < pauseActivities.length - 1 ? allTranscriptsDelimiter : '')
    }

    // create "all transcripts" file
    zip.file(`${program.title}, All Transcripts.txt`, allTranscriptsText)

    // download
    zip.generateAsync({ type: 'blob' }).then(function(content) {
      saveAs(content, `${program.title} - All Pause Transcripts.zip`)
      setStatusText(`Done!`)
      close() // all done!
    })
  }, [days, program, close])

  /**
   * Build array of Pause Activities from Program Days.
   */
  const getPauseActivities = (days: IProgramDay[]): IProgramDayActivity[] => {
    return days.reduce(
      // reducer function
      (accumulator, day) => {
        if (!day.activities) {
          return accumulator // no activities (thus Pauses) for this day!
        }
        const pauseActivities: IProgramDayActivity[] = day.activities.filter(
          activity => activity.type === 'pause', // only look at Pauses
        )
        return [...accumulator, ...pauseActivities]
      },
      [] as IProgramDayActivity[], // initial value: empty array
    )
  }

  /* ---------------------------------------------------------------------------
   * Lifecycle
   * -------------------------------------------------------------------------*/

  // update mount boolean
  React.useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  // update exporting boolean and trigger export
  React.useEffect(() => {
    isExportingRef.current = props.isOpen
    if (isExportingRef.current) {
      exportTranscripts()
    }
  }, [props.isOpen, exportTranscripts])

  /* ---------------------------------------------------------------------------
   * Content
   * -------------------------------------------------------------------------*/

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={props.isOpen}
      onClose={props.close}
      size={'xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Exporting Transcripts...</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Progress max={1.0} min={0.0} value={exportPercent} />
          {statusText}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => props.close()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
