import React from 'react'
import { Flex, Box, Button } from '@chakra-ui/react'

import { ISegment } from 'domains/segments/models/ISegment'
import SegmentPreview from 'domains/pauses/components/SegmentPreview'

interface IProps extends ISegment {
  onSelect: (segment: ISegment) => any
}

export default function SegmentPickerRow(props: IProps) {
  const onSelect = () => props.onSelect(props)

  return (
    <Flex
      direction="row"
      justify="space-between"
      align="center"
      p={3}
      cursor="pointer"
    >
      <SegmentPreview segment={props} />

      <Box ml={3}>
        <Button colorScheme="blue" onClick={onSelect}>
          Add
        </Button>
      </Box>
    </Flex>
  )
}
