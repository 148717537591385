import React from 'react'

import { milliSecondsToFriendlyTime } from 'lib/utils/time'
import IconButton from '~/components/IconButton'
import { addDoc } from 'lib/api/connection'
import {
  getPauseTranscriptsPath,
  IPauseTranscript,
} from '@/pauses/models/IPause'
import { PlusSquareIcon } from '@chakra-ui/icons'

type Props = {
  pauseId: string
  index: number
  msPerPx: number
  timeMarkersPerSecond: number
  onTranscriptAdded: () => any
}

export default function TranscriptTimeMarker({
  pauseId,
  index,
  msPerPx,
  timeMarkersPerSecond,
  onTranscriptAdded,
}: Props) {
  const height = 1000 / msPerPx / timeMarkersPerSecond

  const [pending, setPending] = React.useState(false)

  const time = (index / timeMarkersPerSecond) * 1000
  const friendlyTime = milliSecondsToFriendlyTime(time)
  const exactTime = milliSecondsToFriendlyTime(time, true)

  const onAddTranscript = async () => {
    setPending(true)
    await addDoc(getPauseTranscriptsPath(pauseId), {
      start: time,
      end: time + 2000,
      transcript: '',
      paragraphEnd: false,
    } as IPauseTranscript)
    await onTranscriptAdded()
    setPending(false)
  }

  return (
    <div
      className="group"
      style={{ height, minHeight: height, maxHeight: height }}
    >
      {index % timeMarkersPerSecond === 0 && (
        <p
          className="text-gray-700 text-xs border-t border-gray-300"
          style={{ fontSize: 8 }}
        >
          {friendlyTime}
        </p>
      )}
      {index % timeMarkersPerSecond !== 0 && (
        <div
          className="w-full h-0.5 border-t border-gray-300"
          style={{ fontSize: 8 }}
        />
      )}

      <div className="hidden group-hover:block">
        <IconButton
          onClick={onAddTranscript}
          icon={PlusSquareIcon}
          color="green.500"
          pending={pending}
          aria-label="add transcript"
          tip={`Add Transcript at ${exactTime}`}
        />
      </div>
    </div>
  )
}
