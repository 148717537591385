import React from 'react'
import { Box, FormLabel, Flex, Button } from '@chakra-ui/react'
import { hasValue } from 'lib/utils'
import GenericUploadFileInput from 'lib/components/GenericUploadFileInput'
import { getV2PauseStorageDir, getSegmentStorageDir } from 'lib/api/connection'

/**
 * UploadPauseAsset
 * -----------------------------------------------------------------------------
 */

interface UploadPauseAssetProps
  extends Omit<UploadAssetProps, 'uploadDirPath'> {
  /** Pause Asset ID (usually a pause ID or program ID) */
  pauseAssetId: string
}

export function UploadPauseAsset(props: UploadPauseAssetProps) {
  return (
    <UploadAsset
      {...props}
      uploadDirPath={getV2PauseStorageDir(props.pauseAssetId)}
    />
  )
}

/**
 * UploadSegmentAsset
 * -----------------------------------------------------------------------------
 */

interface UploadSegmentAssetProps
  extends Omit<UploadAssetProps, 'uploadDirPath'> {}

export function UploadSegmentAsset(props: UploadSegmentAssetProps) {
  return (
    <UploadAsset {...props} uploadDirPath={getSegmentStorageDir(props.type)} />
  )
}

/**
 * UploadAsset
 * -----------------------------------------------------------------------------
 */

interface UploadAssetProps {
  /** asset type */
  type: 'MUSIC' | 'IMAGE' | 'VIDEO'

  /** Form label */
  label: string

  /** Firebase Storage upload directory path */
  uploadDirPath: string

  /** current url value (URL to Firebase Storage resource) */
  url?: string

  /** callback when new url value is available */
  onUrlChanged: (url: string) => void
}

function UploadAsset({
  type,
  label,
  uploadDirPath,
  url,
  onUrlChanged,
}: UploadAssetProps) {
  const hasAsset = hasValue(url)

  return (
    <Box>
      <FormLabel>
        {/* Label */}
        {label}

        {/* Asset display */}
        {hasAsset && type === 'MUSIC' && (
          <audio controls={true} className="my-2">
            <source src={url} />
          </audio>
        )}
        {hasAsset && type === 'IMAGE' && (
          <img src={url} alt="Pause Asset" className="h-32 my-2" />
        )}
        {hasAsset && type === 'VIDEO' && (
          <video width="400" controls>
            <source src={url} />
            Your browser does not support HTML video.
          </video>
        )}
      </FormLabel>

      {/* Buttons, Upload Progress */}
      <Flex direction="row">
        {hasAsset && (
          <Button colorScheme="red" onClick={() => onUrlChanged('')} mr={2}>
            Remove
          </Button>
        )}
        <GenericUploadFileInput
          baseFilePath={uploadDirPath}
          onUploaded={onUrlChanged}
          accept={`${type}/*`}
        />
      </Flex>
    </Box>
  )
}
