import { DEFAULT_ORG_PROGRAMS_PATH } from 'lib/api/apiConstants'

export interface IProgramDayActivity {
  type: 'pause'
  title: string
  refId: string
  duration?: number
  required?: boolean
  requiredActivityIds?: string[]
}

export interface IProgramDay {
  id: string
  order: number
  title: string
  description?: string
  activities?: IProgramDayActivity[]
  moduleTitle?: string
  moduleDescription?: string
  moduleVideoAsset?: string
}

export default interface IProgram {
  id: string
  published: boolean
  title: string
  daysCount: number
  description?: string
  averageDailyLength?: string
  backgroundImageAsset?: string
  introVideoAsset?: string
}

export const getProgramPath = (id: string) =>
  `${DEFAULT_ORG_PROGRAMS_PATH}/${id}`

export const getProgramDaysPath = (id: string) =>
  `${DEFAULT_ORG_PROGRAMS_PATH}/${id}/days`

export const getProgramDayPath = (id: string, dayId: string) =>
  `${DEFAULT_ORG_PROGRAMS_PATH}/${id}/days/${dayId}`
