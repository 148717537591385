import { useState } from 'react'
import { duplicatePause } from '../workflow/duplicatePause'

/**
 * Hook to duplicate a Pause, including its transcripts collection data.
 *
 * @param pauseId (optional) The ID of the Pause to duplicate.
 * @returns [isDuplicating, duplicatePause] where:
 *  isDuplicating: boolean - Whether the duplication is in progress.
 * duplicatePause: (pauseId?: string) => Promise<string> - Function to duplicate
 *                 a pause and update state.
 */
export default function useDuplicatePause(
  pauseId?: string,
): [boolean, (paramPauseId?: string) => Promise<string>] {
  // state
  const [isDuplicating, setIsDuplicating] = useState<boolean>(false)

  // function to duplicate a pause and update state
  const localDuplicatePause = async (paramPauseId): Promise<string> => {
    setIsDuplicating(true)
    const newPauseId = await duplicatePause(paramPauseId || pauseId)
    setIsDuplicating(false)
    return newPauseId
  }

  // return
  return [isDuplicating, localDuplicatePause]
}
