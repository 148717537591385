import * as R from 'ramda'

import IPause, {
  getPausePath,
  getPauseSegmentsPath,
} from 'domains/pauses/models/IPause'
import { ISegment } from 'domains/segments/models/ISegment'
import { DEFAULT_ORG_PAUSES_PATH } from 'lib/api/apiConstants'
import { addDoc, getCollection, getDoc } from 'lib/api/connection'

export const duplicatePause = async (id: string): Promise<IPause> => {
  const pausePath = getPausePath(id)
  const p = await getDoc(pausePath)
  const pause = p as IPause

  const newPause = await addDoc<IPause>(DEFAULT_ORG_PAUSES_PATH, {
    ...R.dissoc('id', pause),
    published: false,
    title: `Duplicate of ${pause.title}`,
  })
  const newPauseSegmentsPath = getPauseSegmentsPath(newPause.id)

  const s = await getCollection(getPauseSegmentsPath(id))
  const segments = s as ISegment[]

  for (const segment of segments) {
    await addDoc(newPauseSegmentsPath, R.dissoc('id', segment))
  }

  return newPause as IPause
}
