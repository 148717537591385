import React from 'react'
import { QueryConstraint } from 'firebase/firestore'

import useCollection from 'lib/api/hooks/useCollection'
import ListRaw from 'lib/components/ListRaw'
import uuid from 'uuid'

interface IProps {
  collection: string
  queries?: QueryConstraint[]
  keyExtractor?: (item: any) => string
  RowComponent: any
  DividerComponent?: any
  rowProps?: object
  filter?: any
  refetch?: string
}

export default function List(props: IProps) {
  const [fetched, setFetched] = React.useState(props.refetch || uuid)
  const [collection, pending, fetch] = useCollection(
    props.collection,
    props.queries,
  )

  React.useEffect(() => {
    if (props.refetch && fetched !== props.refetch) {
      setFetched(props.refetch)
      fetch()
    }
  }, [fetched, props.refetch, fetch])

  const finalCollection = pending
    ? []
    : props.filter
    ? collection.filter(props.filter)
    : collection

  return (
    <ListRaw
      keyExtractor={props.keyExtractor}
      RowComponent={props.RowComponent}
      rowProps={props.rowProps}
      DividerComponent={props.DividerComponent}
      items={finalCollection}
    />
  )
}
